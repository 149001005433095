<template>
  <div class="row align-items-center mb-5">

    <!-- <p>{{ prettyTimeToComplete }}</p> -->

    <!-- Error -->
    <div class="col-md-7" v-if="!showProcess && error == true && error_type !== 'inline'">

      <h1>{{ error_title }}</h1>
      <h2>{{ error_msg }}</h2>
      <a :href="error_btn_link" class="button">&lt; {{ error_btn_text }}</a>

    </div>
    <div class="col-md-5" v-if="!showProcess && error == true && error_type !== 'inline'">
      <img src="/img/error.svg" class="paddedImage" />
    </div>
    <!-- / Error -->

    <!-- / Progress indicator -->

    <!-- Reference Code -->
    <div class="col-md-12 processStep"
      v-if="thisStep == 'ref' && showProcess && !complete && timeToComplete > 0">
      <div class="stepContainer">
        <div class="stepHeader">
          <div class="container-fluid">
            <div class="row align-items-center">
              <div class="col-md-12">
                <img src="/img/logo.png" class="processLogo" />
              </div>
              <div class="col-md-12 mt-3 mb-3">
                <p v-if="!rn">{{ $t('verify.h2.enter_ref_no') }}</p>
                <p v-else>{{ $t('verify.h2.check_ref_no') }}</p>
              </div>
            </div>
          </div>
        </div>
        <div class="progress" style="height:4px">
          <div class="progress-bar progress-bar-striped progress-bar-animated" role="progressbar"
            :style="'width: 0%;'" aria-valuenow="0" aria-valuemin="0" aria-valuemax="100"></div>
        </div>
        <div class="stepBody">
          <div class="container">
            <div class="row">
              <div class="col-md-12" v-if="unsupportedFirefox">
                <div class="alert alert-danger"><strong>You are using Firefox on Android.</strong> Due to technical
                  limitations of Firefox on Android, this process will not work. Please use another browser (such as
                  Chrome) or another device.</div>
              </div>
              <div class="col-md-12" v-if="unsupportedFirefoxiOS">
                <div class="alert alert-danger"><strong>You are using Firefox on iOS.</strong> Due to technical
                  limitations of Firefox on iOS, this process will not work. Please use another browser (such as Safari)
                  or another device.</div>
              </div>
              <div class="col-md-12" v-if="inAppBrowser">
                <div class="alert alert-danger"><strong>You are using Facebook or Instagram's in-app browser.</strong>
                  Due to technical limitations of Meta's browser, this process will not work. Please tap the menu
                  top-right (three dots) and choose to open in your device's normal browser.</div>
              </div>
              <div class="col-md-12" v-if="step1_error == true">
                <div class="alert alert-danger" role="alert">
                  {{ error_msg }}
                </div>
              </div>
              <div class="col-md-12">
                <form onsubmit="return false">
                  <div class="form-floating ">
                    <input v-model="referenceCode" ref="refCode" :class="{ 'is-invalid': refIsInvalid }"
                      required="required" class="form-control mb-3" :placeholder="$t('label.reference_code')"
                      ondrop="return false;" autocomplete="off">
                    <label for="floatingInput">{{ $t('label.reference_code') }}</label>
                  </div>
                  <div class="lds-ring" v-if="loading == true">
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                  </div>
                  <button type="submit" v-if="loading == false" @click="checkNumber" class="button align-right mt-4">{{
      $t('button.next') }} &gt;</button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- / Reference Code -->

    <!-- Name & NI -->
    <div class="col-md-12 processStep"
      v-if="thisStep == 'name_ni' && referenceCode !== null && showProcess && !complete && timeToComplete > 0">
      <div class="stepContainer">
        <div class="stepHeader">
                <p>{{ $t('step2.confirmed_ref_no', {
      'attempts':
        this.step2.attempts
    }) }}</p>
          <p>{{ $t('step2.proceed') }}</p>
        </div>
        <div class="progress" style="height:4px">
          <div class="progress-bar progress-bar-striped progress-bar-animated" role="progressbar"
            :style="'width: ' + ((numberOfStepsDone / numberOfSteps * 100)) + '%;'" :aria-valuenow="((numberOfStepsDone / numberOfSteps * 100))" aria-valuemin="0" aria-valuemax="100"></div>
        </div>
        <div class="stepBody">
          <div class="container">
            <div class="row">
              <div class="col-lg-4 col-md-12 readybox">
                <div>
                  <p class="readyicon">
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                      class="bi bi-passport" viewBox="0 0 16 16">
                      <path
                        d="M8 5a3 3 0 1 0 0 6 3 3 0 0 0 0-6M6 8a2 2 0 1 1 4 0 2 2 0 0 1-4 0m-.5 4a.5.5 0 0 0 0 1h5a.5.5 0 0 0 0-1z" />
                      <path
                        d="M3.232 1.776A1.5 1.5 0 0 0 2 3.252v10.95c0 .445.191.838.49 1.11.367.422.908.688 1.51.688h8a2 2 0 0 0 2-2V4a2 2 0 0 0-1-1.732v-.47A1.5 1.5 0 0 0 11.232.321l-8 1.454ZM4 3h8a1 1 0 0 1 1 1v10a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V4a1 1 0 0 1 1-1" />
                    </svg>
                  </p>
                  <p>{{ $t('message.have_your_photo_id_ready') }}</p>
                </div>
              </div>
              <div class="col-lg-4 col-md-12 readybox">
                <div>
                  <p class="readyicon">
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                      class="bi bi-lamp" viewBox="0 0 16 16">
                      <path fill-rule="evenodd"
                        d="M5.04.303A.5.5 0 0 1 5.5 0h5c.2 0 .38.12.46.303l3 7a.5.5 0 0 1-.363.687h-.002q-.225.044-.45.081a33 33 0 0 1-4.645.425V13.5a.5.5 0 1 1-1 0V8.495a33 33 0 0 1-4.645-.425q-.225-.036-.45-.08h-.003a.5.5 0 0 1-.362-.688l3-7ZM3.21 7.116A31 31 0 0 0 8 7.5a31 31 0 0 0 4.791-.384L10.171 1H5.83z" />
                      <path
                        d="M6.493 12.574a.5.5 0 0 1-.411.575c-.712.118-1.28.295-1.655.493a1.3 1.3 0 0 0-.37.265.3.3 0 0 0-.052.075l-.001.004-.004.01V14l.002.008.016.033a.6.6 0 0 0 .145.15c.165.13.435.27.813.395.751.25 1.82.414 3.024.414s2.273-.163 3.024-.414c.378-.126.648-.265.813-.395a.6.6 0 0 0 .146-.15l.015-.033L12 14v-.004a.3.3 0 0 0-.057-.09 1.3 1.3 0 0 0-.37-.264c-.376-.198-.943-.375-1.655-.493a.5.5 0 1 1 .164-.986c.77.127 1.452.328 1.957.594C12.5 13 13 13.4 13 14c0 .426-.26.752-.544.977-.29.228-.68.413-1.116.558-.878.293-2.059.465-3.34.465s-2.462-.172-3.34-.465c-.436-.145-.826-.33-1.116-.558C3.26 14.752 3 14.426 3 14c0-.599.5-1 .961-1.243.505-.266 1.187-.467 1.957-.594a.5.5 0 0 1 .575.411" />
                    </svg>
                  </p>
                  <p>{{ $t('message.in_a_well_lit_room') }}</p>
                </div>
              </div>
              <div class="col-lg-4 col-md-12 readybox">
                <div>
                  <p class="tabletready">
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                      class="bi bi-tablet" viewBox="0 0 16 16">
                      <path
                        d="M12 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1zM4 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2z" />
                      <path d="M8 14a1 1 0 1 0 0-2 1 1 0 0 0 0 2" />
                    </svg>

                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                      class="bi bi-tablet-landscape" viewBox="0 0 16 16">
                      <path
                        d="M1 4a1 1 0 0 1 1-1h12a1 1 0 0 1 1 1v8a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1zm-1 8a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V4a2 2 0 0 0-2-2H2a2 2 0 0 0-2 2z" />
                      <path d="M14 8a1 1 0 1 0-2 0 1 1 0 0 0 2 0" />
                    </svg>
                    <span class="crossovertablet">❌</span>
                  </p>
                  <p>{{ $t('message.if_handheld_rotate_portrait') }}</p>
                </div>
              </div>
              <div class="col-md-12 pb-4 pt-3">
                <hr />
              </div>
              <div class="col-md-12">
                <form class="container inlineform" onsubmit="return false">
                  <div class="row">
                    <div class="col-md-6 mb-3" v-if="step2.nameCheck">
                      <div class="form-floating ">
                        <input :class="{ 'is-invalid': firstNameInvalid }" type="text" class="form-control"
                          v-model="step2_data.first_name" ref="firstName" :placeholder="$t('label.first_name')"
                          required="required" onpaste="return false;" ondrop="return false;" />
                        <label for="floatingInput">{{ $t('label.first_name') }}</label>
                      </div>

                    </div>
                    <div class="col-md-6 mb-3" v-if="step2.nameCheck">
                      <div class="form-floating ">
                        <input type="text" class="form-control" v-model="step2_data.middle_names"
                          :placeholder="$t('label.middle_names')" onpaste="return false;" ondrop="return false;" />
                        <label for="floatingInput">{{ $t('label.middle_names') }}</label>
                      </div>

                    </div>
                    <div class="col-md-6 mb-3" v-if="step2.nameCheck">
                      <div class="form-floating ">
                        <input :class="{ 'is-invalid': surnameInvalid }" type="text" class="form-control"
                          v-model="step2_data.surname" :placeholder="$t('label.surname')" required="required"
                          onpaste="return false;" ondrop="return false;" />
                        <label for="floatingInput">{{ $t('label.surname') }}</label>
                      </div>

                    </div>
                    <div class="col-md-6 mb-3" v-if="step2.niCheck">
                      <div class="form-floating ">
                        <input v-uppercase :class="{ 'is-invalid': national_insurance_numberInvalid }" type="text"
                          class="form-control" ref="niCheck" v-model="step2_data.national_insurance_number"
                          :placeholder="$t('label.nino')" required="required" onpaste="return false;"
                          ondrop="return false;" />
                        <label for="floatingInput">{{ $t('label.nino') }}</label>
                      </div>
                    </div>

                    <div class="col-md-12 mb-3 mt-3">
                      <button type="submit" @click="commitNames" class="button align-right">{{ $t("button.next") }}
                        &gt;</button>
                    </div>
                  </div>
                </form>

              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- / Name & NI -->

    <!-- Date of Birth -->
    <div class="col-md-12 processStep"
      v-if="thisStep == 'dofb' && referenceCode !== null && showProcess && !complete && timeToComplete > 0">
      <div class="stepContainer">
        <div class="stepHeader">
          <p>{{ $t('title.enter_dob') }}</p>
        </div>
        <div class="progress" style="height:4px">
          <div class="progress-bar progress-bar-striped progress-bar-animated" role="progressbar"
            :style="'width: ' + ((numberOfStepsDone / numberOfSteps * 100)) + '%;'" :aria-valuenow="((numberOfStepsDone / numberOfSteps * 100))" aria-valuemin="0" aria-valuemax="100"></div>
        </div>
        <div class="stepBody">
          <div class="container">
            <div class="row">
             <div class="col-md-12">
              <form class="container inlineform processStep" onsubmit="return false">
                  <div class="row">

                    <div class="col-md-4 mb-3">
                      <div class="form-floating">
                        <select ref="dob_date" :class="{ 'is-invalid': dobDateInvalid }" class="form-control"
                          v-model="step2_data.dob_date" :placeholder="$t('label.dob.date')" required="required">
                          <option :value="null" disabled>...</option>
                          <option v-for="date in dates">{{ date }}</option>
                        </select>
                        <label for="floatingInput">{{ $t('label.dob.date') }}</label>
                      </div>

                    </div>

                    <div class="col-md-4 mb-3">
                      <div class="form-floating ">
                        <select :class="{ 'is-invalid': dobMonthInvalid }" class="form-control"
                          v-model="step2_data.dob_month" :placeholder="$t('label.dob.month')" required="required">
                          <option :value="null" disabled>...</option>
                          <option v-for="(month, index) in months" :value="index">{{ month }}</option>
                        </select>
                        <label for="floatingInput">{{ $t('label.dob.month') }}</label>
                      </div>

                    </div>

                    <div class="col-md-4 mb-3">
                      <div class="form-floating ">
                        <div ref="dob_year_tooltip" class="custom_tooltip_container">
                          <div class="custom_tooltip">{{ $t('tooltip.try_now') }}</div>
                        </div>
                        <select ref="dob_year" :class="{ 'is-invalid': dobYearInvalid }" class="form-control"
                          v-model="step2_data.dob_year" :placeholder="$t('label.dob.year')" @change="this.checkYear()"
                          required="required">
                          <option :value="null" disabled>...</option>
                          <option v-for="year in years" :value="year">{{ year }}</option>
                        </select>
                        <label for="floatingInput">{{ $t('label.dob.year') }}</label>
                      </div>

                    </div>

                    <div class="col-md-12 mt-3">
                      <button type="submit" @click="commitDob" class="button align-right">{{ $t("button.next") }}
                        &gt;</button>
                    </div>
                  </div>
                </form>
             </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- / Date of Birth-->

    <!-- Email -->
    <div class="col-md-12 processStep"
      v-if="thisStep == 'email' && referenceCode !== null && showProcess && !complete && timeToComplete > 0">
      <div class="stepContainer">
        <div class="stepHeader">
          <p>{{ $t('title.enter_email') }}</p>
        </div>
        <div class="progress" style="height:4px">
          <div class="progress-bar progress-bar-striped progress-bar-animated" role="progressbar"
            :style="'width: ' + ((numberOfStepsDone / numberOfSteps * 100)) + '%;'" :aria-valuenow="((numberOfStepsDone / numberOfSteps * 100))" aria-valuemin="0" aria-valuemax="100"></div>
        </div>
        <div class="stepBody">
          <div class="container">
            <div class="row">
             <div class="col-md-12">
              <form class="container inlineform processStep" autocomplete="off" onsubmit="return false">

                  <div class="row">
                    <div class="col-md-12 mb-2">
                      <p>{{ $t('label.contact_details_disclaimer') }}</p>
                    </div>

                    <div class="col-md-6 mb-3">
                      <div class="form-floating">
                        <input ref="email" v-lowercase type="email" class="form-control" v-model="step2_data.email"
                          @change="validateEmail" :placeholder="$t('label.email')" onpaste="return false;"
                          ondrop="return false;" autocomplete="off" />
                        <label for="floatingInput">{{ $t('label.email') }}</label>
                      </div>
                    </div>
                    <div class="col-md-6 mb-3"
                      v-if="step2.emailCheck && step2_data.emailPINconfirmed == false && emailPINcheck == false">

                      <div class="form-floating">
                        <input v-lowercase type="email" autocomplete="false" class="form-control"
                          :class="{ 'is-valid': validateEmail(), 'is-invalid': invalidEmail }"
                          v-model="step2_data.email_confirmation" @change="validateEmail"
                          :placeholder="$t('label.email_confirm')" onpaste="return false;" ondrop="return false;" />
                        <label for="floatingInput">{{ $t('label.email_confirm') }}</label>
                      </div>
                    </div>
                    <div class="col-md-6 mt-3">
                      <p><a href="javascript:;" @click="skipEmail()">{{ $t('button.dont_have_email') }}</a></p>
                    </div>
                    <div class="col-md-6 mt-3">
                      <button :disabled="emailsButtonDisabled" v-if="emailPINloading == false && emailPINcheck == false"
                        type="submit" @click="emailCheck" class="button align-right">{{ $t("button.next") }}
                        &gt;</button>
                    </div>
                  </div>

                  <div class="row align-items-center processStep"
                    v-if="emailPINcheck == true && step2_data.email !== null && emailPINloading == false">
                    <div class="col-md-12">
                      <div v-if="step2_data.emailPINconfirmed == false">
                        <p>{{ $t('message.pin_sent_to_confirm_email', { 'email': step2_data.email }) }}</p>
                        <p>{{ $t('message.request_another_pin_clarification') }}</p>
                        <p class="mb-3 mt-3">{{ $t('message.enter_pin_below') }}:</p>
                      </div>
                    </div>

                    <div class="col-md-12">
                      <div class="alert alert-info mt-3" v-if="emailRetries > 1 && !emailPINverified">
                        <p>{{ $t('button.pin_not_received') }} <a href="javascript:;" @click="skipEmail()">{{
      $t('button.click_to_skip') }}</a>.</p>
                      </div>
                    </div>

                  </div>

                </form>
             </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- / Email -->

     <!-- Email Confirm -->
     <div class="col-md-12 processStep"
      v-if="thisStep == 'email_confirm' && referenceCode !== null && showProcess && !complete && timeToComplete > 0">
      <div class="stepContainer">
        <div class="stepHeader">
          <p>{{ $t('title.confirm_email') }}</p>
        </div>
        <div class="progress" style="height:4px">
          <div class="progress-bar progress-bar-striped progress-bar-animated" role="progressbar"
            :style="'width: ' + ((numberOfStepsDone / numberOfSteps * 100)) + '%;'" :aria-valuenow="((numberOfStepsDone / numberOfSteps * 100))" aria-valuemin="0" aria-valuemax="100"></div>
        </div>
        <div class="stepBody">
          <div class="container">
            <div class="row">
             <div class="col-md-12">
              <form class="container inlineform processStep" autocomplete="off" onsubmit="return false">

                  <div class="col-md-12 mb-2" v-if="emailPINloading">
                    <div class="lds-ring">
                      <div></div>
                      <div></div>
                      <div></div>
                      <div></div>
                    </div>
                  </div>

                  <div class="row align-items-center processStep"
                    v-if="emailPINloading == false">
                    <div class="col-md-12">
                      <div v-if="step2_data.emailPINconfirmed == false">
                        <p>{{ $t('message.pin_sent_to_confirm_email', { 'email': step2_data.email }) }}</p>
                        <p>{{ $t('message.request_another_pin_clarification') }}</p>
                        <p class="mb-3 mt-3">{{ $t('message.enter_pin_below') }}:</p>
                      </div>
                    </div>
                    <div class="col-md-7 mb-3">

                      <div class="pincontainer">
                        <input type="tel" @keyup="checkEmailPinBoxes($event, 1)" v-model="emailPINentry_1" maxlength="1"
                          ref="emailPIN_1" class="pinentry form-control" :class="{ 'is-valid': emailPINverified }" />
                        <input type="tel" @keyup="checkEmailPinBoxes($event, 2)" v-model="emailPINentry_2" maxlength="1"
                          ref="emailPIN_2" class="pinentry form-control" :class="{ 'is-valid': emailPINverified }" />
                        <input type="tel" @keyup="checkEmailPinBoxes($event, 3)" v-model="emailPINentry_3" maxlength="1"
                          ref="emailPIN_3" class="pinentry form-control" :class="{ 'is-valid': emailPINverified }" />
                        <input type="tel" @keyup="checkEmailPinBoxes($event, 4)" v-model="emailPINentry_4" maxlength="1"
                          ref="emailPIN_4" class="pinentry form-control" :class="{ 'is-valid': emailPINverified }" />
                        <input type="tel" @keyup="checkEmailPinBoxes($event, 5)" v-model="emailPINentry_5" maxlength="1"
                          ref="emailPIN_5" class="pinentry form-control" :class="{ 'is-valid': emailPINverified }" />
                        <input type="tel" @keyup="checkEmailPinBoxes($event, 6)" v-model="emailPINentry_6" maxlength="1"
                          ref="emailPIN_6" class="pinentry form-control" :class="{ 'is-valid': emailPINverified }" />
                      </div>

                    </div>
                    <div class="col-md-5 mb-3">
                      <p v-if="emailRetryIn > 0 && !emailPINverified" class="retrydisabled">{{ $t('button.retry_in') }}
                        {{
      emailRetryIn }} {{ $t('layout.seconds') }}</p>
                      <p class="sendAnotherPIN" v-if="emailRetryIn <= 0 && !emailPINverified">{{
      $t('label.didnt_receive_it') }} <a href="javascript:;" @click="emailCheck()">{{
      $t('button.send_another_pin') }}</a></p>
                    </div>

                    <div class="col-md-12">
                      <div class="alert alert-info mt-3" v-if="emailRetries > 1 && !emailPINverified">
                        <p>{{ $t('button.pin_not_received') }} <a href="javascript:;" @click="skipEmail()">{{
      $t('button.click_to_skip') }}</a>.</p>
                      </div>
                    </div>

                    <div class="col-md-12 mt-3">
                      <div class="lds-ring" v-if="emailPINverified">
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                      </div>
                    </div>

                  </div>

                </form>
             </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- / Email Confirm -->



    <!-- Mobile -->
    <div class="col-md-12 processStep"
      v-if="thisStep == 'mobile' && referenceCode !== null && showProcess && !complete && timeToComplete > 0">
      <div class="stepContainer">
        <div class="stepHeader">
          <p>{{ $t('title.enter_mob') }}</p>
        </div>
        <div class="progress" style="height:4px">
          <div class="progress-bar progress-bar-striped progress-bar-animated" role="progressbar"
          :style="'width: ' + ((numberOfStepsDone / numberOfSteps * 100)) + '%;'" :aria-valuenow="((numberOfStepsDone / numberOfSteps * 100))" aria-valuemin="0" aria-valuemax="100"></div>
        </div>
        <div class="stepBody">
          <div class="container">
            <div class="row">
             <div class="col-md-12">
              <form class="container inlineform processStep" autocomplete="off" onsubmit="return false">
                  <div class="row">

                    <div class="col-md-12 mb-2" v-if="mobilePINrequested == false">
                      <p>{{ $t('label.contact_details_disclaimer') }}</p>
                    </div>

                    <div class="col-md-6 mb-3" v-if="mobilePINrequested == false">
                      <p class="mb-2">{{ $t('question.do_you_have_a_mobile') }}</p>
                      <select v-model="hasMobile" @change="checkForMobile()" class="form-control">
                        <option value="null" selected disabled>{{ $t('label.choose') }}...</option>
                        <option value="true">{{ $t('label.yes') }}</option>
                        <option value="false">{{ $t('label.no') }}</option>
                      </select>
                    </div>

                    <div class="col-md-6 mb-3" v-if="hasMobile == 'true' && mobilePINrequested == false">
                      <p class="mb-2">{{ $t("step2.mobile_country") }}</p>
                      <select @change="getCountryCode()" v-model="step2_data.mobileCountry" class="form-control">
                        <option value="null" selected disabled>{{ $t('label.choose') }}...</option>
                        <option v-for="(country, index) in step2.countries" :value="index">{{ country }}</option>
                      </select>
                    </div>

                    <div class="col-md-6 mb-3"
                      v-if="step2.mobileCheck && step2_data.mobilePINconfirmed == false && mobilePINcheck == false && hasMobile == 'true' && step2_data.mobileCountry !== null && mobilePINrequested == false">

                      <div class="input-group">
                        <span class="input-group-text">+{{ step2_data.mobileDiallingCode }}</span>
                        <div class="form-floating">
                          <input type="tel" class="form-control" v-model="step2_data.mobile" @change="validateMobile"
                            @keyup="validateMobile" :placeholder="$t('label.mobile_number')" onpaste="return false;"
                            ondrop="return false;" autocomplete="off" />
                          <label for="floatingInput">{{ $t('label.mobile_number') }}</label>
                        </div>
                      </div>
                    </div>

                    <div class="col-md-6 mb-3"
                      v-if="step2.mobileCheck && step2_data.mobilePINconfirmed == false && mobilePINcheck == false && hasMobile == 'true' && step2_data.mobileCountry !== null && mobilePINrequested == false">

                      <div class="input-group">
                        <span class="input-group-text">+{{ step2_data.mobileDiallingCode }}</span>
                        <div class="form-floating">
                          <input type="tel" class="form-control" v-model="step2_data.mobile_confirmation"
                            @change="validateMobile" @keyup="validateMobile"
                            :placeholder="$t('label.mobile_number_confirmation')" onpaste="return false;"
                            ondrop="return false;" autocomplete="off" />
                          <label for="floatingInput">{{ $t('label.mobile_number_confirmation') }}</label>
                        </div>
                      </div>
                    </div>

                  </div>
                  <div class="row mt-3 align-middle">

                    

                    <div class="col-md-6" v-if="mobilePINrequested == false">
                      <p><a href="javascript:;" @click="skipMobile()">{{ $t('button.click_to_skip') }}</a></p>
                    </div>

                  

                    <div class="col-md-6" v-if="mobilePINrequested == false">
                      <button :disabled="mobileButtonDisabled"
                        v-if="mobilePINloading == false && mobilePINcheck == false" type="submit" @click="mobileCheck()"
                        class="button align-right">{{ $t("button.next") }} &gt;</button>
                      <div class="paddingTop lds-ring" v-if="mobilePINloading == true">
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                      </div>
                    </div>

                    <div class="paddingTop lds-ring" v-if="mobilePINloading == true">
                      <div></div>
                      <div></div>
                      <div></div>
                      <div></div>
                    </div>

                   
                  </div>
                </form>
             </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- / Mobile -->

    <!-- Mobile Confirm -->
    <div class="col-md-12 processStep"
      v-if="thisStep == 'mobile_confirm' && referenceCode !== null && showProcess && !complete && timeToComplete > 0">
      <div class="stepContainer">
        <div class="stepHeader">
          <p>{{ $t('title.enter_mob') }}</p>
        </div>
        <div class="progress" style="height:4px">
          <div class="progress-bar progress-bar-striped progress-bar-animated" role="progressbar"
          :style="'width: ' + ((numberOfStepsDone / numberOfSteps * 100)) + '%;'" :aria-valuenow="((numberOfStepsDone / numberOfSteps * 100))" aria-valuemin="0" aria-valuemax="100"></div>
        </div>
        <div class="stepBody">
          <div class="container">
            <div class="row">
             <div class="col-md-12">
              <form class="container inlineform processStep" autocomplete="off" onsubmit="return false">
                  <div class="row">



                    <div class="paddingTop lds-ring" v-if="mobilePINloading == true">
                      <div></div>
                      <div></div>
                      <div></div>
                      <div></div>
                    </div>

                    <div class="row align-items-center processStep"
                      v-if="mobilePINcheck && mobilePINrequested && mobilePINloading == false">
                      <div class="col-md-12" v-if="step2_data.mobilePINconfirmed == false">
                        <p>{{ $t('message.pin_sent_to_confirm_mobile', { 'mobile': step2_data.mobile }) }}</p>
                        <p>{{ $t('message.request_another_pin_clarification') }}</p>
                        <p class="mb-3 mt-3">{{ $t('message.enter_pin_below') }}:</p>
                      </div>


                      <div class="col-md-7 mb-3">
                        <div class="pincontainer">
                          <input type="tel" @keyup="checkMobilePinBoxes($event, 1)" v-model="smsPINentry_1"
                            maxlength="1" ref="smsPIN_1" class="pinentry form-control"
                            :class="{ 'is-valid': mobilePINverified }" />
                          <input type="tel" @keyup="checkMobilePinBoxes($event, 2)" v-model="smsPINentry_2"
                            maxlength="1" ref="smsPIN_2" class="pinentry form-control"
                            :class="{ 'is-valid': mobilePINverified }" />
                          <input type="tel" @keyup="checkMobilePinBoxes($event, 3)" v-model="smsPINentry_3"
                            maxlength="1" ref="smsPIN_3" class="pinentry form-control"
                            :class="{ 'is-valid': mobilePINverified }" />
                          <input type="tel" @keyup="checkMobilePinBoxes($event, 4)" v-model="smsPINentry_4"
                            maxlength="1" ref="smsPIN_4" class="pinentry form-control"
                            :class="{ 'is-valid': mobilePINverified }" />
                          <input type="tel" @keyup="checkMobilePinBoxes($event, 5)" v-model="smsPINentry_5"
                            maxlength="1" ref="smsPIN_5" class="pinentry form-control"
                            :class="{ 'is-valid': mobilePINverified }" />
                          <input type="tel" @keyup="checkMobilePinBoxes($event, 6)" v-model="smsPINentry_6"
                            maxlength="1" ref="smsPIN_6" class="pinentry form-control"
                            :class="{ 'is-valid': mobilePINverified }" />
                        </div>
                      </div>

                      <div class="col-md-5 mb-3">
                        <p v-if="smsRetryIn > 0 && !mobilePINverified" class="retrydisabled">{{ $t('button.retry_in') }}
                          {{
      smsRetryIn }} {{ $t('layout.seconds') }}</p>
                        <p class="sendAnotherPIN" v-if="smsRetryIn <= 0 && !mobilePINverified">{{
      $t('label.didnt_receive_it') }} <a href="javascript:;" @click="mobileCheck()">{{
      $t('button.send_another_pin') }}</a></p>
                      </div>

                      <div class="col-md-12">
                        <div class="alert alert-info mt-3" v-if="smsRetries > 1 && !mobilePINverified">
                          <p>{{ $t('button.pin_not_received') }} <a href="javascript:;" @click="skipMobile()">{{
      $t('button.click_to_skip') }}</a>.</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
             </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- / Mobile Confirm -->

    <!-- Address -->
    <div class="col-md-12 processStep"
      v-if="thisStep == 'address' && referenceCode !== null && showProcess && !complete && timeToComplete > 0">
      <div class="stepContainer">
        <div class="stepHeader">
          <p>{{ $t('step2.2.h2') }}</p>
        </div>
        <div class="progress" style="height:4px">
          <div class="progress-bar progress-bar-striped progress-bar-animated" role="progressbar"
          :style="'width: ' + ((numberOfStepsDone / numberOfSteps * 100)) + '%;'" :aria-valuenow="((numberOfStepsDone / numberOfSteps * 100))" aria-valuemin="0" aria-valuemax="100"></div>
        </div>
        <div class="stepBody">
          <div class="container">
            <div class="row">
             <div class="col-md-12">
              <form class="container inlineform" onsubmit="return false" @change="validateAddress()" @keyup="validateAddress()">
            <div class="row">
              <div class="col-md-12 mb-3">
                <div class="form-floating">
                  <select class="form-select" :class="{ 'is-invalid': step2_data.countryInvalid }"
                    v-model="step2_data.country" id="countryOfResidence" required="required">
                    <option value="null" selected disabled>{{ $t('label.choose') }}...</option>
                    <option v-for="(country, index) in step2.countries" :value="index">{{ country }}</option>
                  </select>
                  <label for="countryOfResidence">{{ $t('label.country_of_residence') }}</label>
                </div>
              </div>
              <div class="col-md-12 mb-3">
                <div class="form-floating ">
                  <input type="text" ref="addressLine1" class="form-control"
                    :class="{ 'is-invalid': step2_data.addressLine1Invalid }" v-model="step2_data.address_line_1"
                    onpaste="return false;" ondrop="return false;" autocomplete="off"
                    :placeholder="$t('label.address_line_1')" />
                  <label for="floatingInput">{{ $t('label.address_line_1') }}</label>
                </div>
              </div>
              <div class="col-md-12 mb-3">
                <div class="form-floating ">
                  <input type="text" :class="{ 'is-invalid': step2_data.addressLine2Invalid }" class="form-control"
                    v-model="step2_data.address_line_2" onpaste="return false;" ondrop="return false;"
                    autocomplete="off" :placeholder="$t('label.address_line_2')" />
                  <label for="floatingInput">{{ $t('label.address_line_2') }}</label>
                </div>
              </div>
              <div class="col-md-12 mb-3">
                <div class="form-floating ">
                  <input type="text" class="form-control" v-model="step2_data.address_line_3" onpaste="return false;"
                    ondrop="return false;" autocomplete="off" :placeholder="$t('label.address_line_3')" />
                  <label for="floatingInput">{{ $t('label.address_line_3') }}</label>
                </div>
              </div>
              <div class="col-md-12 mb-3">
                <div class="form-floating ">
                  <input type="text" class="form-control" v-model="step2_data.address_line_4" onpaste="return false;"
                    ondrop="return false;" autocomplete="off" :placeholder="$t('label.address_line_4')" />
                  <label for="floatingInput">{{ $t('label.address_line_4') }}</label>
                </div>
              </div>
              <div class="col-md-12 mb-3">
                <div class="form-floating ">
                  <input type="text" class="form-control" v-model="step2_data.address_line_5" onpaste="return false;"
                    ondrop="return false;" autocomplete="off" :placeholder="$t('label.address_line_5')" />
                  <label for="floatingInput">{{ $t('label.address_line_5') }}</label>
                </div>
              </div>
              <div class="col-md-12 mb-3">
                <div class="form-floating ">
                  <input type="text" class="form-control" v-model="step2_data.address_line_6" onpaste="return false;"
                    ondrop="return false;" autocomplete="off" :placeholder="$t('label.address_line_6')" />
                  <label for="floatingInput">{{ $t('label.address_line_6') }}</label>
                </div>
              </div>
              <div class="col-md-12 mb-3">
                <div class="form-floating ">
                  <input v-uppercase type="text" class="form-control"
                    :class="{ 'is-invalid': step2_data.postcodeInvalid }" v-model="step2_data.postcode"
                    onpaste="return false;" ondrop="return false;" autocomplete="off"
                    :placeholder="$t('label.postcode')" />
                  <label for="floatingInput">{{ $t('label.postcode') }}</label>
                </div>
              </div>
              <div class="col-md-12 mb-3" v-if="!step2_data.mobile && !step2_data.email">
                <div class="input-group">
                  <span class="input-group-text">+{{ step2_data.mobileDiallingCode }}</span>
                  <div class="form-floating">
                    <input type="tel" class="form-control" v-model="step2_data.phone"
                      :placeholder="$t('label.phone_number')" onpaste="return false;" ondrop="return false;"
                      autocomplete="off" />
                    <label for="floatingInput">{{ $t('label.phone_number') }}</label>
                  </div>
                </div>
              </div>
              <div class="col-md-12">
                <button :disabled="addressButtonDisabled" type="submit" @click="submitAddress()" class="button">{{
      $t("button.next") }} &gt;</button>
                <div class="paddingTop lds-ring" v-if="submittingData == true">
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                </div>
              </div>
            </div>
          </form>
             </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- / Address -->

    <!-- Extra Questions -->
    <div class="col-md-12 processStep"
      v-if="thisStep == 'extraquestions' && referenceCode !== null && showProcess && !complete && timeToComplete > 0">
      <div class="stepContainer">
        <div class="stepHeader">
          <p v-if="extraPageTitle !== null">{{ extraPageTitle }}</p>
        </div>
        <div class="progress" style="height:4px">
          <div class="progress-bar progress-bar-striped progress-bar-animated" role="progressbar"
          :style="'width: ' + ((numberOfStepsDone / numberOfSteps * 100)) + '%;'" :aria-valuenow="((numberOfStepsDone / numberOfSteps * 100))" aria-valuemin="0" aria-valuemax="100"></div>
        </div>
        <div class="stepBody">
          <div class="container">
            <div class="row">
             <div class="col-md-12">
              <form class="row" @submit.prevent="submitForm">

                <div v-for="(field, fieldIndex) in extraPages[this.extraPagesCurrent].inputs" class="col-md-12 mb-3 inputgroup">

                  <div v-if="field.type == 'grid'" class="card">
                    <div class="card-body">
                    <p class="p-2 pb-0">{{ field.label }}</p>
                    <table class="table">
                      <thead>
                        <tr>
                          <th v-for="subfield in field.columns[0]" scope="col">{{ subfield.name }}</th>
                        </tr>
                      </thead>
                      <tbody>
                        <template v-for="(rows, row) in field.columns" :key="row">
                         
                        <tr>
                          <td v-for="(subfield, index) in rows" :key="index">

                            <select class="form-control form-select" v-if="subfield.style == 'select'" v-model="questions['RG|'+field.fieldname+'|'+subfield.fieldname+'|'+row]">
                              <option value="" disabled>Please select...</option>
                              <option v-for="listitem in subfield.list" :value="listitem">{{ listitem }}</option>
                            </select>

                            <select class="form-control form-select" v-else-if="subfield.style == 'yesno'" v-model="questions['RG|'+field.fieldname+'|'+subfield.fieldname+'|'+row]">
                              <option value="Yes">Yes</option>
                              <option value="No">No</option>
                            </select>

                            <textarea v-else-if="subfield.style == 'textarea'" class="form-control" v-model="questions['RG|'+field.fieldname+'|'+subfield.fieldname+'|'+row]" onpaste="return false;" ondrop="return false;" autocomplete="off" :placeholder="subfield.label" ></textarea>

                          <input v-else :type="subfield.style" class="form-control" v-model="questions['RG|'+field.fieldname+'|'+subfield.fieldname+'|'+row]"  onpaste="return false;"
                    ondrop="return false;" autocomplete="off" :placeholder="subfield.label"  />

                        </td>
                        </tr>
                        </template>
                        <tr>
                          <td :colspan="field.columns[0].length" class="text-right"><a href="javascript:;" @click="addDataItem(field)" class="btn btn-sm btn-secondary text-white">+ Add another</a></td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  </div>

                  <div class="form-floating" v-else>

                            <select class="form-control form-select" v-if="field.type == 'select'" v-model="questions[field.fieldname]">
                              <option v-for="listitem in field.list" :value="listitem">{{ listitem }}</option>
                            </select>

                            <select class="form-control form-select" v-else-if="field.type == 'yesno'" v-model="questions[field.fieldname]">
                              <option value="Yes">Yes</option>
                              <option value="No">No</option>
                            </select>

                            <textarea v-else-if="field.type == 'textarea'" class="form-control" v-model="questions[field.fieldname]" :name="field.fieldname" onpaste="return false;" ondrop="return false;" autocomplete="off" :placeholder="field.label" :maxlength="field.length"></textarea>

                          <input v-else :type="field.type" class="form-control" v-model="questions[field.fieldname]" :name="field.fieldname" onpaste="return false;"
                    ondrop="return false;" autocomplete="off" :placeholder="field.label" :maxlength="field.length" />


                  
                  <label :for="field.label">{{ field.label }}</label>
                </div>

              </div>

              <div class="col-md-12">
                <button type="submit" @click="submitExtraQuestions()" class="button">{{
      $t("button.next") }} &gt;</button>
              </div>
            </form>
             </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- / Extra Questions -->

    <!-- Bio Consent -->
    <div class="col-md-12 processStep"
      v-if="thisStep == 'bioconsent' && referenceCode !== null && showProcess && !complete && timeToComplete > 0">
      <div class="stepContainer">
        <div class="stepHeader">
          <p>Consent to sharing your biometric information</p>
        </div>
        <div class="progress" style="height:4px">
          <div class="progress-bar progress-bar-striped progress-bar-animated" role="progressbar"
          :style="'width: ' + ((numberOfStepsDone / numberOfSteps * 100)) + '%;'" :aria-valuenow="((numberOfStepsDone / numberOfSteps * 100))" aria-valuemin="0" aria-valuemax="100"></div>
        </div>
        <div class="stepBody">
          <div class="container">
            <div class="row">
             <div class="col-md-12">
             <p>We will need to perform a liveness and/or identity document scan which means that we will capture some biometric information about you, which requires your consent. You can choose not to grant us consent but this process will not complete unless you do.</p>
             <p>This information is shared only with the institution requesting this check and will never be shared with a third party.</p>
             <p>Do you wish to proceed?</p>
             </div>
             <div class="col-md-6 mt-3">
                <a @click="denyConsent()" href="javascript:;" class="button secondary">I do not consent</a>
            </div>
            <div class="col-md-6 mt-3 right">
                <a @click="confirmConsent()" href="javascript:;" class="button">I agree - please proceed &gt;</a>
          </div>
        </div>
      </div>
    </div>
    </div>
    </div>
    <!-- / Bio Consent -->


    <!-- FaceTec -->
    <div class="col-md-12 processStep"
      v-if="thisStep == 'facetec' && referenceCode !== null && showProcess && !complete && timeToComplete > 0">
      <div class="stepContainer">
        <div class="stepHeader">
          <p>{{ $t('title.confirm_identity') }}</p>
        </div>
        <div class="progress" style="height:4px">
          <div class="progress-bar progress-bar-striped progress-bar-animated" role="progressbar"
          :style="'width: ' + ((numberOfStepsDone / numberOfSteps * 100)) + '%;'" :aria-valuenow="((numberOfStepsDone / numberOfSteps * 100))" aria-valuemin="0" aria-valuemax="100"></div>
        </div>
        <div class="stepBody">
          <div class="container">

            <div class="row" v-if="FaceTecLoadProgress < 100">
              <div class="col-md-12">
                <div class="progress">
                  <div class="progress-bar progress-bar-striped progress-bar-animated" role="progressbar"
                    :style="'width: ' + FaceTecLoadProgress + '%'" :aria-valuenow="FaceTecLoadProgress"
                    aria-valuemin="0" aria-valuemax="100">{{ FaceTecLoadProgress }}%</div>
                </div>
                <p v-if="FaceTecLoading < 10">{{ $t('facetec.messages.loading') }}...</p>
                <p v-if="FaceTecLoading >= 10 && FaceTecLoading < 20">{{ $t('facetec.message.loading_slow') }}...</p>
                <p v-if="FaceTecLoading >= 20">{{ $t('facetec.message.loading_very_slow') }}...</p>
              </div>
            </div>

            <div class="row facetec" v-if="FaceTecLoadProgress >= 90">

              <div class="col-md-12 mb-3">

                <div id="main-interface">
                  <div id="vocal-icon-container" class="ft-button">
                    <div><img id="vocal-guidance-icon-minimal" aria-label="Minimal Vocal Guidance Enabled" disabled
                        class="vocal-icon" src="/images/vocal_minimal.png"
                        onclick="FaceTec.onVocalGuidanceSettingsButtonPressed()"></div>
                    <div><img id="vocal-guidance-icon-full" aria-label="Full Vocal Guidance Enabled" disabled
                        class="vocal-icon vocal-guidance-icon-full display-none" src="/images/vocal_full.png"
                        onclick="FaceTec.onVocalGuidanceSettingsButtonPressed()"></div>
                    <div><img id="vocal-guidance-icon-off" aria-label="Vocal Guidance Disabled" disabled
                        class="vocal-icon vocal-guidance-icon-off display-none" src="/images/vocal_off.png"
                        onclick="FaceTec.onVocalGuidanceSettingsButtonPressed()"></div>
                  </div>

                  <div id="identityConfirm" v-if="!mobileIsReady">

                    <p>{{ $t('facetec.messages.we_need_the_following') }}</p>

                    <ul>
                      <li v-if="this.step2.livenessCheck">{{ $t('facetec.messages.scan_of_face') }}</li>
                      <li v-if="this.step2.id1Check">{{ $t('facetec.messages.scan_of_id') }}</li>
                      <li v-if="this.step2.id2Check">{{ $t('facetec.messages.scan_of_id') }}</li>
                      <li v-if="this.step2.doc1Check">{{ $t('facetec.messages.scan_of_id') }}</li>
                      <li v-if="this.step2.doc2Check">{{ $t('facetec.messages.scan_of_id') }}</li>

                    </ul>

                    <div class="alert alert-danger" role="alert" v-if="faceTecError">
                      {{ this.faceTecErrorMsg }}
                    </div>

                  </div>
                </div>

              </div>

              <div class="col-md-6 col-sm-12" v-if="!isMobile() && !mobileIsReady">

                <div class="well">
                  <span class="label">Recommended</span>
                  <h2>{{ $t('facetec.continue_on_mobile') }}</h2>

                  <p>{{ $t('facetec.scan_qr') }}</p>
                  <div class="center" id="qrcode"></div>
                  <div class="lds-ring" v-if="qrCode == null">
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                  </div>
                  <div class="center qrDetails" v-if="qrCode !== null">
                    <p>{{ $t('facetec.or_go_to') }}</p>
                    <p><strong>{{ getQrLink() }}</strong></p>
                    <p>{{ $t('facetec.on_mobile_device') }}</p>
                  </div>
                </div>
              </div>

              <div class="col-md-6 col-sm-12 relative" v-if="!mobileIsReady">
                <div class="or">{{ $t('label.or') }}</div>
                <div class="well">

                  <h2 v-if="!isMobile()">{{ $t('facetec.title.continue_on_this_device') }}</h2>

                  <p v-if="!isMobile()">{{ $t('facetec.alternatively_continue_on_device') }}</p>
                  <p>{{ $t('facetec.click_next_to_continue') }}</p>
                  <p>{{ $t('facetec.press_allow') }}</p>

                  <div id="controls">

                    <button id="photo-id-scan-button" :disabled="faceTecDisabled" v-if="faceTecDisabled == false"
                      @click="nextStep()" class="button align-right">Next &gt;</button>
                    <div class="lds-ring" v-if="faceTecDisabled || FaceTecLoadProgress < 100">
                      <div></div>
                      <div></div>
                      <div></div>
                      <div></div>
                    </div>
                    <button id="documentUploadButton" onclick="uploadDocument()" class="button">Upload &gt;</button>
                    <p id="status" aria-live="polite">Initializing...</p>
                  </div>
                  <div id="additional-screen" display="flex">
                    <div id="additional-screen-image-and-text">
                      <img id="additional-screen-logo" aria-hidden="true" tabindex="-1">
                      <h2 aria-live="polite">Server Upgrade In Progress</h2>
                      <div id="additional-screen-text" aria-live="polite"></div>
                    </div>
                    <button id="additional-screen-button" class="big-button browser-button ft-button"
                      aria-live="polite">OK</button>
                  </div>
                  <div class="loading-session-token-container">
                    <p id="loading-session-token-text" aria-live="polite">Network connection slow, please continue to
                      wait...</p>
                  </div>
                </div>
              </div>

              <div class="col-md-12" v-if="mobileIsReady">
                <div class="well center">
                  <div class="container-fluid">
                    <div class="row align-items-center">
                      <div class="col-md-3">
                        <img src="/img/phone.svg" class="mobileIcon" />
                      </div>
                      <div class="col-md-9">
                        <div class="lds-ring notext">
                          <div></div>
                          <div></div>
                          <div></div>
                          <div></div>
                        </div>
                        <h2>{{ $t('facetec.continue_on_device') }}</h2>
                        <p v-if="mobileStatus == null || mobileStatus == 1">{{ $t('facetec.status.continue_on_device')
                          }}</p>
                        <p v-if="mobileStatus == 2">{{ $t('facetec.status.performing_liveness') }}</p>
                        <p v-if="mobileStatus == 3">{{ $t('facetec.status.performing_id_scan') }}</p>
                        <p v-if="mobileStatus == 4">{{ $t('facetec.status.process_completed') }}</p>
                      </div>
                    </div>
                  </div>

                </div>
              </div>

            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- / FaceTec -->

    <!-- ID document -->
    <div class="col-md-12 processStep"
      v-if="thisStep == 'id'">
      <div class="stepContainer">
        <div class="stepHeader">
          <p>Choose the type of idenitity document</p>
        </div>
        <div class="progress" style="height:4px">
          <div class="progress-bar progress-bar-striped progress-bar-animated" role="progressbar"
          :style="'width: ' + ((numberOfStepsDone / numberOfSteps * 100)) + '%;'" :aria-valuenow="((numberOfStepsDone / numberOfSteps * 100))" aria-valuemin="0" aria-valuemax="100"></div>
        </div>
        <div class="stepBody">
          <div class="container">
            <div class="row">
              <div class="col-md-12 processStep">
                <p>Please choose the type of photo identity document you will be presenting to scan</p>
              </div>
              <div class="col-md-6 processStep">
                <select v-model="typeOfID" class="form-select">
                  <option value="null" selected disabled>Choose...</option>
                  <option value="passport">Passport</option>
                  <option value="drivinglicence">Driving Licence</option>
                  <option value="idcard">Photo ID Card</option>
                  <option value="other">Other</option>
                </select>
              </div>
              <div class="col-md-12 mt-4" v-if="typeOfID == 'other'">
                <p class="alert alert-info"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-info-circle" viewBox="0 0 16 16">
  <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16"/>
  <path d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0"/>
</svg> We strongly recommend you use either a passport, driving licence or national ID card but if you wish to proceed with another form of photo ID, please click 'Proceed to Scan' below.</p>
                </div>
              <div class="col-md-12 mt-4" v-if="typeOfID == 'passport'">
                <div class="well well-sm">
                <p>When scanning your passport, <strong>please ensure you present the photo page, which is usually page 3,</strong> when asked to scan your ID. For example:</p>
                <p><img src="/img/examplePassport.jpg" class="passportexample" /></p>
                <p class="alert alert-info"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-info-circle" viewBox="0 0 16 16">
  <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16"/>
  <path d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0"/>
</svg> You can identify this page by the two lines of machine code at the bottom of the page</p>
                </div>
              </div>
              <div class="col-md-12 mt-4" v-if="typeOfID !== null">
                <a v-if="!IDScanLoading" @click="openIDScan()" href="javascript:;" class="button">Proceed to scan</a>
                <a v-else class="button">Please wait...</a>
                </div>
              
            </div>
          </div>
      </div>
    </div>
  </div>
    <!-- / ID document -->

    <!-- Complete -->
    <div class="col-md-12 processStep"
      v-if="thisStep == 'complete'">
      <div class="stepContainer">
        <div class="stepHeader">
          <p>{{ $t('title.completed') }}</p>
        </div>
        <div class="progress" style="height:4px">
          <div class="progress-bar progress-bar-striped progress-bar-animated" role="progressbar"
          :style="'width: ' + ((numberOfStepsDone / numberOfSteps * 100)) + '%;'" :aria-valuenow="((numberOfStepsDone / numberOfSteps * 100))" aria-valuemin="0" aria-valuemax="100"></div>
        </div>
        <div class="stepBody">
          <div class="container">
            <div class="row">
              <div class="col-md-12 processStep">
                <h2>✅ {{ $t('success.h1') }}</h2>
                <p>{{ $t('success.h2') }}</p>
              </div>
              <div class="col-md-12 mt-3">
                <a @click="navigateHome" href="javascript:;" class="button">{{ $t('button.finish') }}</a>
              </div>
            </div>
          </div>
      </div>
    </div>
  </div>
    <!-- / Complete -->




    

    <div class="col-md-12 processStep" v-if="showProcess && timeToComplete <= 0 && !complete">
      <!-- <div class=""> -->
      <div class="stepContainer">
        <div class="stepHeader">
          <p>{{ $t('error.session_expired') }}</p>
        </div>
        <div class="col-md-12">
          <div class="stepBody">
            <div class="container">
              <div class="row">
                <p>{{ $t('error.start_again') }}</p>
              </div>
              <div class="col-md-12 mt-3">
                <a href="/" class="button">{{ $t('button.start_again') }}</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- / Step 2 -->

  </div>
</template>

<script>


import { Config } from "/js/facetec.js";
window.Config = Config;
fetch('/assets/FaceTec_OCR_Customization.json')
  .then(response => {
    return response.json();
  })
  .then(data => window.ocrLocalizationJSON = data);

export default {
  name: 'Validentity',
  props: {
    code: String,
    rn: String,
    cid: String
  },
  data() {
    return {
      emailPINentry_1: null,
      emailPINentry_2: null,
      emailPINentry_3: null,
      emailPINentry_4: null,
      emailPINentry_5: null,
      emailPINentry_6: null,
      smsPINentry_1: null,
      smsPINentry_2: null,
      smsPINentry_3: null,
      smsPINentry_4: null,
      smsPINentry_5: null,
      smsPINentry_6: null,
      tmpJson: '',
      handoff: false,
      cameraAccessAllowed: false,
      faceTecErrorMsg: null,
      startPoll: null,
      faceTecError: false,
      mobileStatus: null,
      mobileIDdone: false,
      mobileLivenessDone: false,
      mobileProcessDone: false,
      mobileIsReady: false,
      FaceTecLoading: 0,
      FaceTecLoadingInterval: null,
      FaceTecSDKLoaded: false,
      FaceTecSDKInterval: null,
      FaceTecUIFunctionsInterval: null,
      FaceTecUIFunctionsLoaded: false,
      FaceTecUtilitiesInterval: null,
      FaceTecUtilitiesLoaded: false,
      FaceTecStatusMessagesInterval: null,
      FaceTecStatusMessagesLoaded: false,
      FaceTecThemeHelpersInterval: null,
      FaceTecThemeHelopersLoaded: false,
      FaceTecAdditionalScreensInterval: null,
      FaceTecAdditionalScreensLoaded: false,
      FaceTecSoundUtilitiesInterval: null,
      FaceTecSoundUtilitiesLoaded: false,
      FaceTecProcessorsInterval: null,
      FaceTecProcessorsLoaded: false,
      FaceTecControllerInterval: null,
      FaceTecControllerLoaded: false,
      FaceTecLoadProgress: 10,
      faceTecReady: false,
      isAQrProcess: false,
      complete: false,
      faceTecDisabled: true,
      progress: 0,
      refIsInvalid: false,
      firstNameInvalid: false,
      surnameInvalid: false,
      national_insurance_numberInvalid: false,
      dobDateInvalid: false,
      dobMonthInvalid: false,
      dobYearInvalid: false,
      showProcess: true,
      timeToComplete: 899,
      prettyTimeToComplete: '...',
      step1_complete: false,
      totalSteps: 4,
      thisStep: 'ref',
      step1_error: false,
      referenceCode: null,
      error: false,
      error_type: 'full',
      error_msg: null,
      error_title: null,
      error_btn_link: null,
      error_btn_text: null,
      loading: false,
      attempts: 6,
      emailPINrequested: false,
      mobilePINrequested: false,
      emailPINtoConfirm: null,
      mobilePINtoConfirm: null,
      emailPINcheck: false,
      mobilePINcheck: false,
      emailsButtonDisabled: true,
      mobileButtonDisabled: true,
      addressButtonDisabled: true,
      submittingData: false,
      step2_part_a_complete: false,
      step2_part_b_complete: false,
      step2_part_c_complete: false,
      step2_part_d_complete: false,
      emailPINloading: false,
      mobilePINloading: false,
      emailSkip: false,
      smsSkip: false,
      emailSent: null,
      smsSent: null,
      smsCounterSet: false,
      emailCounterSet: false,
      invalidEmail: false,
      emailPINflashed: false,
      mobilePINflashed: false,
      emailPINverified: false,
      mobilePINverified: false,
      hasMobile: null,
      emailNotReceived: false,
      smsNotReceived: false,
      emailRetries: 0,
      smsRetries: 0,
      emailRetryIn: 0,
      smsRetryIn: 0,
      qrCode: null,
      step2: {
        nameCheck: false,
        dobCheck: false,
        niCheck: false,
        addressCheck: false,
        emailCheck: false,
        mobileCheck: false,
        amlCheck: false,
        livenessCheck: false,
        id1Check: false,
        id2Check: false,
        doc1Check: false,
        doc2Check: false,
        emailConfirm: false,
        mobileConfirm: false,
        token: null,
        year: null
      },
      step2_data: {
        first_name: null,
        middle_names: null,
        surname: null,
        national_insurance_number: null,
        email: null,
        dob_date: null,
        dob_month: null,
        dob_year: null,
        time_submitted: null,
        number: null,
        amlCheck: false,
        downlinkSpeed: null,
        address_line_1: '',
        address_line_2: '',
        address_line_3: null,
        address_line_4: null,
        address_line_5: null,
        address_line_6: null,
        postcode: '',
        phone: null,
        mobile: null,
        country: null,
        user_agent: null,
        email_confirmation: null,
        mobilePINuserEntry: null,
        mobilePINconfirmed: false,
        emailPINuserEntry: null,
        emailPINconfirmed: false,
        mobilePINconfirmed: false,
        mobileCountry: null,
        mobileDiallingCode: '',
        mobile_confirmation: null,
        addressLine1Invalid: false,
        addressLine2Invalid: false,
        countryInvalid: false,
        postcodeInvalid: false,
        time_started: null,
        bioconsent: '?'
      },
      months: {},
      dates: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31],
      years: [],
      IDandLivenessScan: {},
      FirstID: {},
      SecondID: {},
      Document1: {},
      Document2: {},
      Liveness: {},
      persons_dob_year: 1900,
      unsupportedFirefox: false,
      unsupportedFirefoxiOS: false,
      inAppBrowser: false,
      extraPages: {},
      extraPagesCurrent: 0,
      extraPageTitle: null,
      extraPageDescription: null,
      questions: {},
      numOfExtraPages: 0,
      showStep: 'ref',
      checksDone: {
        ref: false,
        name_ni: false,
        dofb: false,
        email: false,
        email_confirm: false,
        mobile: false,
        mobile_confirm: false,
        address: false,
        extraquestions: false,
        bioconsent: false,
        facetec: false,
        liveness: false,
        id1: false,
        id2: false,
        doc1: false,
        doc2: false,
        completion: false
      },
      numberOfSteps: 1, // start with one for Ref entry 
      numberOfStepsDone: 0,
      typeOfID: null,
      frontOfIDHack: null,
      IDScanLoading: false,
      externalDatabaseRefID: null,
      currentIDScan: null,
      isRunningOnOtherDevice: false
    }
  },
  directives: {
    uppercase: {
      updated(el) {
        el.value = el.value.toUpperCase();
      }
    },
    lowercase: {
      updated(el) {
        el.value = el.value.toLowerCase();
      }
    }
  },
  methods: {
    addDataItem(field) {
      // alert(count);
      // console.log(field);
      let template = field.columns[0];
      let new_columns = [];
      // console.log('templates:');
      // console.log(template);
      template.forEach((item, index) => {
        let newitem = [];
        newitem.list = item.list;
        newitem.style = item.style;
        newitem.label = item.label;
        // newitem.length = item.length;
        newitem.fieldname = item.fieldname;
        this.questions[newitem.fieldname] = null;
        new_columns.push(newitem);
       console.log(newitem);
      });
      console.log(this.questions);
      field.columns.push(new_columns);
    },
    confirmConsent() {
      this.checksDone.bioconsent = true;
      this.step2_data.bioconsent = 'P';
      this.postJson('Bio consent form - passed');
      this.nextStep();
    },
    denyConsent() {
      if(confirm('By denying consent, you will not be able to complete this verification process. Are you sure you wish to continue?')) {
        this.checksDone.bioconsent = true;
        this.checksDone.facetec = true;
        this.checksDone.liveness = true;
        this.checksDone.id1 = true;
        this.checksDone.id2 = true;
        this.step2_data.bioconsent = 'R';
        this.postJson('Bio consent form - denied');
        // this.completeProcess();
        this.nextStep();
      }
    },
    setupExtraPages() {
      this.numOfExtraPages = Object.keys(this.extraPages).length;
      this.numberOfSteps += this.numOfExtraPages;
      // console.log('num of extra pages' + this.numOfExtraPages);
      // console.log(this.extraPages);
      // console.log(Object.keys(this.extraPages).length + ' extra pages');
      // console.log(this.extraPages[this.extraPagesCurrent].title);
      this.extraPageTitle = this.extraPages[this.extraPagesCurrent].title;
      
    },
    skipToFaceTec() { // dev mode thing
      this.checksDone.ref = true;
      this.checksDone.dofb = true;
      this.checksDone.email = true;
      this.checksDone.email_confirm = true;
      this.checksDone.mobile = true;
      this.checksDone.mobile_confirm = true;
      this.checksDone.address = true;
      this.checksDone.extraquestions = true;
      // this.checksDone.facetec = true;
      // this.checksDone.liveness = true;
      this.step2.id1Check = true;
      this.step2.livenessCheck = true;
      // this.loadFaceTec();
      this.nextStep();
    },
    skipToExtraQuestions() { // dev mode thing
      this.checksDone.ref = true;
      this.checksDone.name_ni = true;
      this.checksDone.dofb = true;
      this.checksDone.email = true;
      this.checksDone.email_confirm = true;
      this.checksDone.mobile = true;
      this.checksDone.mobile_confirm = true;
      this.checksDone.address = true;
      this.checksDone.extraquestions = false;
      // this.checksDone.facetec = true;
      // this.checksDone.liveness = true;
      this.step2.id1Check = false;
      this.step2.livenessCheck = false;
      // this.loadFaceTec();
      this.nextStep();
    },
    submitExtraQuestions() {
      // console.log(Object.keys(this.extraPages).length);
      this.postJson('Completed ' + this.extraPages[this.extraPagesCurrent].description);
      // console.log('The extra Qs data is:');
      // console.log(this.questions);
      if(this.numOfExtraPages == (this.extraPagesCurrent+1)) {
        this.extraPageTitle = null;
        this.checksDone.extraquestions = true;
        this.nextStep();
      } else {
        this.extraPagesCurrent++;
        this.stepsDone++;
        this.updateProgress();
        this.extraPageTitle = this.extraPages[this.extraPagesCurrent].title;
      }
      
    },
    updateProgress() {
      // Calculate steps completed
      var stepsDone = 0;
      Object.keys(this.checksDone).forEach(key => {
                  if(this.checksDone[key] == true)
                  stepsDone++;
                });
                this.numberOfStepsDone = stepsDone;
    },
    nextStep() {
      // console.log('Running nextStep!');
      // console.log('This step is ' + this.thisStep);

      console.log(this.checksDone);
     
      if(this.checksDone.ref == false && !this.handoff) {
        // console.log('Loading ref step');
        this.thisStep = 'ref';
        this.updateProgress();
        setTimeout(() => {
          this.$refs.refCode.focus();
        }, 250);
        this.toTop();
        return;
      } else if(this.checksDone.name_ni == false && (this.step2.nameCheck || this.step2.niCheck) && !this.handoff) {
        // console.log('Loading name_ni step');
        this.thisStep = 'name_ni';
        this.updateProgress();
        this.toTop();
        if(this.step2.nameCheck) {
          setTimeout(() => {
                    this.$refs.firstName.focus();
                }, 250);
        } else {
          setTimeout(() => {
                    this.$refs.niCheck.focus();
                }, 250);
        }
        return;
      } else if(this.checksDone.dofb == false && this.step2.dobCheck && !this.handoff) {
        // console.log('Loading dofb step');
        this.thisStep = 'dofb';
        this.updateProgress();
        this.toTop();
        setTimeout(() => {
          this.$refs.dob_date.focus()
        }, 250);
        return;
      } else if(this.checksDone.email == false && this.step2.emailCheck && !this.handoff) {
        // console.log('Loading email step');
        this.thisStep = 'email';
        this.updateProgress();
        this.toTop();
        setTimeout(() => {
          this.$refs.email.focus();
        }, 250);
        return;
      } else if(this.checksDone.email_confirm == false && this.step2.emailConfirm && !this.handoff) {
        // console.log('Loading email confirm step');
        this.thisStep = 'email_confirm';
        this.updateProgress();
        this.toTop();
        setTimeout(() => {
          this.$refs.emailPIN_1.focus();
        }, 1000);
        return;
      } else if(this.checksDone.mobile == false && this.step2.mobileCheck && !this.handoff) {
        // console.log('Loading mobile step');
        this.thisStep = 'mobile';
        this.updateProgress();
        this.toTop();
        return;
      } else if(this.checksDone.mobile_confirm == false && this.step2.mobileConfirm && !this.handoff) {
        // console.log('Loading mobile confirm step');
        this.thisStep = 'mobile_confirm';
        this.updateProgress();
        this.toTop();
        return;
      } else if(this.checksDone.address == false && this.step2.addressCheck && !this.handoff) {
        // console.log('Loading address step');
        this.thisStep = 'address';
        this.updateProgress();
        this.toTop();
        setTimeout(() => {
            this.$refs.addressLine1.focus();
        }, 250);
        return;
      } else if(this.checksDone.extraquestions == false && this.numOfExtraPages > 0 && !this.handoff) {
        // console.log('Loading extraquestions step(s)');
        this.thisStep = 'extraquestions';
        this.updateProgress();
        this.toTop();
        return;
      } else if(this.checksDone.bioconsent == false && this.step2.requiresconsent && !this.handoff) {
        // console.log('Loading extraquestions step(s)');
        this.thisStep = 'bioconsent';
        this.updateProgress();
        this.toTop();
        return;
      } else if(this.checksDone.facetec == false && (this.step2.livenessCheck || this.step2.id1Check || this.step2.id2Check || this.isAQrProcess)) {
        // console.log('Loading facetec step');
        this.thisStep = 'facetec';
        // this.loadFaceTec();
        if (this.code == null) {
            this.loadFaceTec();
          }
        this.updateProgress();
        this.checksDone.facetec = true;
        this.toTop();
        return;
      } else if(this.checksDone.liveness == false && (this.step2.livenessCheck || this.isAQrProcess)) {
        // console.log('Loading facetec step');
        // if(this.startPoll !== null) {
        //   clearInterval(this.startPoll);
        // }
        this.thisStep = 'facetec';
        this.openLivenessScan();
        // this.loadFaceTec();
        // if (this.code == null) {
        //     this.loadFaceTec();
        //   }
        this.updateProgress();
        this.toTop();
        return;
      } else if(this.checksDone.id1 == false && (this.step2.id1Check || this.isAQrProcess)) {
        // console.log('Loading facetec step');
        // if(this.startPoll !== null) {
        //   clearInterval(this.startPoll);
        // }
        this.thisStep = 'id';
        // this.openLivenessScan();
        // this.loadFaceTec();
        // if (this.code == null) {
        //     this.loadFaceTec();
        //   }
        this.updateProgress();
        this.toTop();
        return;
      } else {
        this.thisStep = 'complete';
        this.completeProcess();
        // this.updateProgress();
        if (!this.isAQrProcess) {
          clearInterval(this.startPoll);
        }
        
        // this.complete = true;
        // this.checksDone.completion = true;
        this.toTop();
      }

    
      // if(this.thisStep == 'extraquestions') {
      //   this.thisStep = 4;
      // } else if(this.thisStep == "3" || this.thisStep == 3) {
      //   if(this.numOfExtraPages > 0) {
      //     this.thisStep = 'extraquestions';
      //   } else {
      //     this.thisStep++;
      //   }
      // } else {
      //   this.thisStep++;
      // }
      // this.toTop();
    },
    toTop() {
      setTimeout(function () {
        window.scrollTo(0, 0);
      }, 250);
    },
    submitAddress() {
      if (this.step2_data.country == null) {
        this.step2_data.countryInvalid = true;
      } else {
       
        this.postJson('Submitted address details');
        this.checksDone.address = true;
        this.nextStep();
      }

    },
    validateAddress() {
      if (this.step2_data.country == null) {
        this.step2_data.countryInvalid = true;
      }
      if (this.step2_data.address_line_1.length == 0) {
        this.addressLine1Invalid = true;
      }
      if (this.step2_data.address_line_2.length == 0) {
        this.addressLine2Invalid = true;
      }
      if (this.step2_data.postcode.length == 0) {
        this.postcodeInvalid = true;
      }
      if (this.step2_data.country !== null && this.step2_data.address_line_1.length > 1 && this.step2_data.address_line_2.length > 1 && this.step2_data.postcode.length > 2) {
        this.addressButtonDisabled = false;
      } else {
        this.addressLine1Invalid = false;
        this.addressLine2Invalid = false;
        this.addressButtonDisabled = true;
      }
    },
    skipMobile() {
      this.checksDone.mobile = true;
      this.checksDone.mobile_confirm = true;
      this.postJson('Skipped mobile entry');
      this.nextStep();
    },
    skipEmail() {
      this.checksDone.email = true;
      this.checksDone.email_confirm = true;
      this.postJson('Skipped email entry');
      this.nextStep();
    },
    checkForMobile() {
      if (this.hasMobile == 'false') {
       
        this.step2_part_d_complete = true;
        this.mobileButtonDisabled = false;
      }
    },
    commitNames() {
      var errors = false;
      if (this.step2.nameCheck && !this.step2_data.first_name || this.step2_data.first_name && this.step2_data.first_name.length < 1) {
        errors = true;
        this.firstNameInvalid = true;
      } else {
        this.firstNameInvalid = false;
      }
      if (this.step2.nameCheck && !this.step2_data.surname || this.step2_data.surname && this.step2_data.surname.length < 1) {
        errors = true;
        this.surnameInvalid = true;
      } else {
        this.surnameInvalid = false;
      }
      if (this.step2.niCheck && !this.step2_data.national_insurance_number || this.step2_data.national_insurance_number && this.step2_data.national_insurance_number.length < 1) {
        errors = true;
        this.national_insurance_numberInvalid = true;
      } else {
        this.national_insurance_numberInvalid = false;
      }
      if (!errors) {
        this.checksDone.name_ni = true;
        this.logToMonitor('Name details entered successfully');
        this.postJson('Submitted name and NINO');
        this.nextStep();
        
        // this.toTop();
        // setTimeout(() => {
        //   this.$refs.dob_date.focus()
        // }, 250);
      }

    },
    commitDob() {
      var errors = false;
      if (!this.step2_data.dob_date || this.step2_data.dob_date == null) {
        errors = true;
        this.dobDateInvalid = true;
      } else {
        this.dobDateInvalid = false;
      }
      if (!this.step2_data.dob_month || this.step2_data.dob_month == null) {
        errors = true;
        this.dobMonthInvalid = true;
      } else {
        this.dobMonthInvalid = false;
      }
      if (!this.step2_data.dob_year || this.step2_data.dob_year == null) {
        errors = true;
        this.dobYearInvalid = true;
      } else {
        this.dobYearInvalid = false;
      }
      if (!errors) {
        this.logToMonitor('Date of birth entered successfully');
        this.postJson('Submitted date of birth');
        this.checksDone.dofb = true;
        this.nextStep();
        
        this.toTop();
        
      }
    },
    timer() {
      setInterval(() => {
        var minutes = Math.floor(this.timeToComplete / 60);
        var seconds = this.timeToComplete - (minutes * 60);
        if (minutes > 1) {
          this.prettyTimeToComplete = minutes + ' ' + this.$t("layout.minutes") + ', ' + seconds + ' ' + this.$t("layout.seconds");
        } else if (minutes == 1) {
          this.prettyTimeToComplete = minutes + ' ' + this.$t("layout.minute") + ', ' + seconds + ' ' + this.$t("layout.seconds");
        } else {
          this.prettyTimeToComplete = seconds.toString().padStart(2, '0') + ' ' + this.$t("layout.seconds");
        }
        this.timeToComplete--;
      }, 1000);
    },
    navigateHome() {
      this.clearFormData();
      window.location.href = "/";
    },
    clearFormData() {
      var thisStep = this.thisStep;
      var complete = this.complete;
      Object.keys(this.checksDone).forEach(key => {
        this.checksDone[key] = false;
      });
      Object.keys(this.$data).forEach(key => {
        this.$data[key] = null;
      });
      this.thisStep = thisStep;
      this.complete = complete;
    },
    getCountryCode() {
      axios
        .post('/api/getCountryCode', {
          countryCode: this.step2_data.mobileCountry,
          csrf: valToken.csrfToken
        })
        .then((response) => {
          this.step2_data.mobileDiallingCode = response.data;
          this.step2_data.country = this.step2_data.mobileCountry;
        })
    },
    isMobile() {
      if (this.isAQrProcess) {
        return true;
      } else {
        const regex = /Mobi|Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i;
        return regex.test(navigator.userAgent);
      }
    },
    checkNumber() {

      if (this.referenceCode.length < 8) {
        this.refIsInvalid = true;
      } else {
        this.refIsInvalid = false;
        this.loading = true;
        axios
          .post('/api/checknumber', {
            number: this.referenceCode,
            user_agent: navigator.userAgent,
            csrf: valToken.csrfToken,
            cid: this.cid
          })
          .then((response) => {
            if (!response.data.error && !response.data.success) {
              this.refIsInvalid = true;
              this.loading = false;
            } else if ((response.data.error && response.data.error == true) || response.data.length == 0) {
              this.logToMonitor('Checking number ' + this.referenceCode + ' failed');
              if (response.data.error_type !== 'inline') {
                this.showProcess = false;
              } else {
                this.step1_error = true;
                this.refIsInvalid = true;
              }
              this.error = true;
              this.error_type = response.data.error_type;
              this.error_msg = response.data.error_msg;
              this.error_title = response.data.error_title;
              this.error_btn_text = response.data.error_btn_text;
              this.error_btn_link = response.data.error_btn_link;
              this.loading = false;
            } else if (response.data.success && response.data.success == true) {
              this.timer();
              this.writeLog(response.data);
              // check browser and device
              if (response.data.browser !== "OK") {
                this.throwGeneralError(this.$t('error.unsupported_device_or_browser'), this.$t("error.unsupported"));
                this.logToMonitor('Checking number ' + this.referenceCode + ' passed but device/browser failed');
              } else {
                this.step2 = response.data;
                this.logToMonitor('Checking number ' + this.referenceCode + ' passed');
                
                this.persons_dob_year = (response.data.year && response.data.year.length == 4 ? response.data.year : 1900);
                this.checksDone.ref = true;
                this.nextStep();

                
               
                // this.thisStep = 2;
                // this.step1_complete = true;
                // set up fields as required
                this.step2_data.number = this.referenceCode;
                this.step2_data.amlCheck = response.data.amlCheck;
                // assign checks 
                // this.step2.nameCheck = response.data.nameCheck;
                // this.step2.dobCheck = response.data.dobCheck;
                // this.step2.niCheck = response.data.Product.niCheck;
                // this.step2.addressCheck = response.data.Product.addressCheck;
                // this.step2.emailCheck = response.data.Product.emailCheck;
                // this.step2.mobileCheck = response.data.Product.mobileCheck;
                // this.step2.amlCheck = response.data.Product.amlCheck;

                this.postJson('Entered ref number and passed');

                // Calculate how many steps there are
                if(this.step2.nameCheck || this.step2.niCheck) {
                  this.numberOfSteps++;
                }
                if(this.step2.dobCheck) {
                  this.numberOfSteps++;
                }
                if(this.step2.emailCheck) {
                  this.numberOfSteps++;
                }
                if(this.step2.emailConfirm) {
                  this.numberOfSteps++;
                }
                if(this.step2.mobileCheck) {
                  this.numberOfSteps++;
                }
                if(this.step2.mobileConfirm) {
                  this.numberOfSteps++;
                }
                if(this.step2.addressCheck) {
                  this.numberOfSteps++;
                }
                if(this.step2.requiresconsent) {
                  this.numberOfSteps++;
                }
                if(this.step2.livenessCheck) {
                  this.numberOfSteps++;
                }
                if(this.step2.id1Check) {
                  this.numberOfSteps++;
                }
                if(this.step2.id2Check) {
                  this.numberOfSteps++;
                }
                if(this.step2.doc1Check) {
                  this.numberOfSteps++;
                }
                if(this.step2.doc2Check) {
                  this.numberOfSteps++;
                }
                
                // ADD ONE STEP FOR FACETEC BUT WILL COMPUTE THIS WHEN STEPS ARE BROKEN UP
                this.numberOfSteps++;

                

                
                this.extraPages = response.data.pages;
                // console.log(this.step2);
                this.step2_data.user_agent = navigator.userAgent;
                // set up process
                this.getYears();
                // if (this.step2.emailCheck === false) {
                //   this.step2_part_b_complete = true;
                //   this.writeLog('no email check')
                // }
                // this.step2_data.time_started = ;
                // this.toTop();

                setTimeout(() => {
                  if (new Date().getFullYear() - this.persons_dob_year < 70) {
                    this.$refs.firstName.focus();
                  }
                }, 250);

                // tailor form
                if (this.step2.niCheck == false && this.step2.nameCheck == false) {
                  this.step2_part_a_complete = true;
                }
                if (this.step2.dobCheck == false) {
                  this.step2_part_b_complete = true;
                }

                // temporarily skip to step 4
                // this.thisStep = 4;
                // this.step2_part_a_complete = true;
                // this.step2_part_b_complete = true;
                // this.step2_part_c_complete = true;
                // this.step2_part_d_complete = true;
                this.setupExtraPages();

                // this.skipToExtraQuestions();

                

              }

            }
          }).catch((error) => {
            this.error = true;
            // this.showProcess = false;
            // console.log(error.response);
            this.step1_error = true;
            if (error.response && error.response.status == 429) {
              this.logToMonitor('Checking number ' + this.referenceCode + ' but rejected as reached attempts threshold');
              this.error_msg = this.$t("error.attempts_reached_desc");
            } else {
              this.logToMonitor('Checking number ' + this.referenceCode + ' failed with generic error');
              this.error_msg = this.$t("error.our_system");
            }
            // this.error_type = (error == '');
            // this.error_msg = error;
            this.error_title = this.$t('label.error');
            this.error_btn_text = 'button';
            this.error_btn_link = '/verify';
            this.loading = false;
          })
      }

    },
    getMonths() {
      setInterval(() => {
        this.months = {
          1: this.$t("label.january"),
          2: this.$t("label.february"),
          3: this.$t("label.march"),
          4: this.$t("label.april"),
          5: this.$t("label.may"),
          6: this.$t("label.june"),
          7: this.$t("label.july"),
          8: this.$t("label.august"),
          9: this.$t("label.september"),
          10: this.$t("label.october"),
          11: this.$t("label.november"),
          12: this.$t("label.december"),
        }
      }, 1000);
    },
    generateRandomYears(birthYear, numberOfYears) {
      const years = [];
      for (let i = 0; i < numberOfYears; i++) {
        const randomOffset = Math.floor(Math.random() * 25) - 12; // Generate a random number between -12 and 12
        years.push(parseInt(birthYear) + randomOffset);
      }
      return years.sort();
    },
    checkYear() {
      if (this.step2_data.dob_year == this.$t('label.not_listed')) {
        this.step2.year = null;
        this.years = [];
        this.getYears();
        this.step2_data.dob_year = null;
        $(this.$refs.dob_year_tooltip).fadeIn(130);
      } else {
        $(this.$refs.dob_year_tooltip).hide();
      }
    },
    getYears() {
      if (this.step2.year !== null && this.step2.year > 1900) {

        var yearsArray = [];
        var birthYear = parseInt(this.step2.year);
        var numberToDeduct = Math.floor(Math.random() * (12 - 5 + 1)) + 5;
        var numberToAdd = Math.floor(Math.random() * (12 - 5 + 1)) + 5;
        var startAt = birthYear - numberToDeduct;
        var endAt = birthYear + numberToAdd;

        while (startAt <= endAt) {
          yearsArray.push(startAt);
          startAt++;
        }

        yearsArray.push(this.$t('label.not_listed'));

        this.years = yearsArray;

      } else {
        var i = new Date().getFullYear();
        while (i >= 1900) {
          this.years.push(i);
          i--;
        }
      }

    },
    mobileCheck() {
      if (this.step2.mobileConfirm && this.hasMobile == 'true' && this.step2_data.mobileCountry !== null) {
        this.smsNotReceived = false;
        this.smsRetries++;
        
        this.mobilePINloading = true;
        this.mobilePINrequested = true;
        this.smsPINentry_1 = null;
        this.smsPINentry_2 = null;
        this.smsPINentry_3 = null;
        this.smsPINentry_4 = null;
        this.smsPINentry_5 = null;
        this.smsPINentry_6 = null;
        axios
          .post('/api/confirm-comms', {
            type: 'mobile',
            mobile: this.step2_data.mobile,
            dialingCode: this.step2_data.mobileDiallingCode,
            csrf: valToken.csrfToken
          })
          .then((response) => {
            // this.writeLog(response);
            if (response.data.error && response.data.error == true) {
              // this.writeLog(response.data);
              return false;
            } else if (response.data.success && response.data.success == true) {
              // this.writeLog(response.data);
              this.smsRetryIn = 30;
              this.smsSent = Date.now();
              this.mobilePINtoConfirm = response.data.pin;
              this.mobilePINloading = false;
              this.mobilePINcheck = true;
              this.checksDone.mobile = true;
              this.nextStep();
              setTimeout(() => {
                this.$refs.smsPIN_1.focus();
              }, 250);
              if (this.smsCounterSet == false) {
                this.smsCounterSet = true;
                setInterval(() => {
                  if (this.smsRetryIn > 0) {
                    this.smsRetryIn--;
                  } else {
                    this.smsRetryIn = 0;
                  }
                }, 1000);
              }

              return true;
            }
          });
      } else {
        this.mobilePINcheck = true;
        this.checksDone.mobile = true;
        this.checksDone.mobile_confirm = true;
        this.nextStep();
      }
    },
    emailCheck() {
      this.checksDone.email = true;
      if (this.step2.emailConfirm) {
        this.emailNotReceived = false;
        this.emailRetries++;
        
        this.emailPINloading = true;
        this.emailPINrequested = true;
        this.emailPINentry_1 = null;
        this.emailPINentry_2 = null;
        this.emailPINentry_3 = null;
        this.emailPINentry_4 = null;
        this.emailPINentry_5 = null;
        this.emailPINentry_6 = null;
        axios
          .post('/api/confirm-comms', {
            type: 'email',
            email: this.step2_data.email,
            csrf: valToken.csrfToken
          })
          .then((response) => {
            if (response.data.error && response.data.error == true) {
              return false;
            } else if (response.data.success && response.data.success == true) {
              
              this.emailRetryIn = 30;
              this.emailSent = Date.now();
              this.emailPINtoConfirm = response.data.pin;
              this.emailPINloading = false;
              this.emailPINcheck = true;
              if (this.emailCounterSet == false) {
                this.emailCounterSet = true;
                setInterval(() => {
                  if (this.emailRetryIn > 0) {
                    this.emailRetryIn--;
                  } else {
                    this.emailRetryIn = 0;
                  }
                }, 1000);
              }
              return true;
            }
          });
      } else {
       
        // this.emailPINcheck = true;
      }
      this.nextStep();
    },
    checkMobilePin() {

      if (this.step2_data.mobilePINuserEntry == this.mobilePINtoConfirm) {
        this.smsNotReceived = false;
        if (this.mobilePINflashed == false) {
          this.postJson('Mobile PIN verified');
          this.mobilePINflashed = true;

          this.mobilePINverified = true;
          this.step2_data.mobilePINconfirmed = true;
          // this.step2_part_d_complete = true;
          

          setTimeout(() => {
            this.checksDone.mobile_confirm = true;
            this.nextStep();
          }, 1000);

        }
      }
    },
    checkMobilePinBoxes(event, box) {

      // console.log(event);

      if (event.key == "ArrowRight") { // right arrow
        if (box < 6) {
          this.$refs['smsPIN_' + (box + 1)].focus();
        }
      } else if (event.key == "ArrowLeft") { // left arrow
        if (box > 1) {
          this.$refs['smsPIN_' + (box - 1)].focus();
        }
      } else if (event.key == 'Backspace') { // backspace
        if (box > 1) {
          this.$refs['smsPIN_' + (box - 1)].focus();
        }
        this['smsPINentry_' + box] = null;
      } else {
        // console.log('running checksmspin');
        if (this['smsPINentry_' + box] !== '' && this['smsPINentry_' + box] !== null) {
          // console.log(this['smsPINentry_' + box]);
          if (box !== 6) {
            var i = box + 1;
            // console.log('advancing to ' + i);
            this.$refs['smsPIN_' + box].blur();
            this.$refs['smsPIN_' + i].focus();
          }
        }
      }

      this.step2_data.mobilePINuserEntry = '' + this.smsPINentry_1 + this.smsPINentry_2 + this.smsPINentry_3 + this.smsPINentry_4 + this.smsPINentry_5 + this.smsPINentry_6;

      this.checkMobilePin();

    },
    checkEmailPin() {
      if (this.step2_data.emailPINuserEntry == this.emailPINtoConfirm) {
        if (this.emailPINflashed == false) {
          this.postJson('Email PIN verified');
          this.emailPINflashed = true;

          this.emailPINverified = true;
          setTimeout(() => {
            this.step2_data.emailPINconfirmed = true;
            // this.step2_part_b_complete = true;
            // this.step2_part_c_complete = true;
            this.checksDone.email_confirm = true;
            this.nextStep();
            
            // this.toTop();
          }, 1000);
        }
      }
    },
    checkEmailPinBoxes(event, box) {

      if (event.key == "ArrowRight") { // right arrow
        if (box < 6) {
          this.$refs['emailPIN_' + (box + 1)].focus();
        }
      } else if (event.key == "ArrowLeft") { // left arrow
        if (box > 1) {
          this.$refs['emailPIN_' + (box - 1)].focus();
        }
      } else if (event.key == 'Backspace') { // backspace
        if (box > 1) {
          this.$refs['emailPIN_' + (box - 1)].focus();
        }
        this['emailPINentry_' + box] = null;
      } else {
        // console.log('running checkemailpin');
        if (this['emailPINentry_' + box] !== '' && this['emailPINentry_' + box] !== null) {
          // console.log(this['emailPINentry_' + box]);
          if (box !== 6) {
            var i = box + 1;
            // console.log('advancing to ' + i);
            this.$refs['emailPIN_' + box].blur();
            this.$refs['emailPIN_' + i].focus();
          }
        }
      }

      this.step2_data.emailPINuserEntry = '' + this.emailPINentry_1 + this.emailPINentry_2 + this.emailPINentry_3 + this.emailPINentry_4 + this.emailPINentry_5 + this.emailPINentry_6;

      this.checkEmailPin();

    },
    validateEmail() {
      if (this.step2_data.email && this.step2_data.email !== '' && this.step2_data.email_confirmation && this.step2_data.email_confirmation !== '') {
        if (this.step2_data.email == this.step2_data.email_confirmation) {
          if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(this.step2_data.email) && /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(this.step2_data.email_confirmation) && this.step2_data.email == this.step2_data.email_confirmation) {
            this.invalidEmail = false;
            this.emailsButtonDisabled = false;
            return true;
          } else {
            this.invalidEmail = true;
            this.emailsButtonDisabled = true;
            return false;
          }
        } else {
          this.invalidEmail = true;
          this.emailsButtonDisabled = true;
          return false;
        }
      } else {
        this.invalidEmail = false;
        this.emailsButtonDisabled = true;
        return false;
      }
    },
    validateMobile() {
      if (this.step2_data.mobile && this.step2_data.mobile.length > 6 && this.step2_data.mobile_confirmation && this.step2_data.mobile_confirmation.length > 6 && this.step2_data.mobile == this.step2_data.mobile_confirmation) {
        this.mobileButtonDisabled = false;
      } else {
        this.mobileButtonDisabled = true;
      }
    },
    checkCameraAccess() {
      var accessAllowed = false;
      // Chromium and Firefox
      if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
        navigator.mediaDevices.getUserMedia({ video: true })
          .then(function (stream) {
            const tracks = stream.getTracks();
            tracks.forEach(track => track.stop());
            stream = null;
            this.cameraAccessAllowed = true;
            accessAllowed = true;
          })
          .catch(function (error) {
            this.cameraAccessAllowed = false;
            accessAllowed = false;
          });
      } else {
        this.cameraAccessAllowed = false;
        accessAllowed = false;
      }
      return accessAllowed;
    },
    // openFaceTecScans() {

    //   // alert('Tip: If you are scanning a passport for your ID, please present the PHOTO page as the front of the ID; not the front cover.');

    //   this.logToMonitor('Beginning FaceTec process');
    //   this.faceTecError = false;
    //   FaceTec.onEnrollUserPressed()
    //   if (this.isAQrProcess) {
    //     this.updateMobileStatus(2);
    //   } else {
    //     clearInterval(this.startPoll);
    //   }
    // },
    openLivenessScan() {
      this.postJson('Opening Liveness scan');
      this.logToMonitor('Beginning Liveness scan');
      this.faceTecError = false;
      // alert('openning liveness scan');
      FaceTec.onEnrollUserPressed();
      if (this.isAQrProcess) {
        this.checksDone.liveness = true;
        this.updateMobileStatus(2);
      } else {
        clearInterval(this.startPoll);
      }
    },
    openIDScan() {
      this.postJson('Opening ID scan');
      if (this.isAQrProcess) {
        // reset mobile status back to beginning
        this.updateMobileStatus(3);
      }
      // this.checksDone.facetec = true;
      this.IDScanLoading = true;
      if(this.step2.id1Check && this.checksDone.id1 == false) {
        this.currentIDScan = 'id1';
        this.logToMonitor('Beginning ID 1 scan');
      } else if(this.step2.id2Check && this.checksDone.id2 == false) {
        this.currentIDScan = 'id2';
        this.logToMonitor('Beginning ID 2 scan');
      } else {
        // nothing more to do - we shouldn't even be here
      }
      
      this.faceTecError = false;
      FaceTec.onPhotoIDMatchPressed();

      // put in a really hacky way of altering the prompts by targetting #DOM_FT_idScanReticleMessage
      this.frontOfIDHack = setInterval(() => {
        if(document.getElementById("DOM_FT_idScanSelectionMessage") && document.getElementById("DOM_FT_idScanSelectionMessage").innerHTML == "Prepare to Scan<br>Your ID Document") {
          if(this.typeOfID == 'passport') {
            document.getElementById("DOM_FT_idScanSelectionMessage").innerHTML = 'Open your passport<br />on the photo page<br />(usually page 3)';
          } else if(this.typeOfID == 'drivinglicence') {
            document.getElementById("DOM_FT_idScanSelectionMessage").innerHTML = 'Prepare to scan<br />your driving licence';
          } 
        }

        if(document.getElementById("DOM_FT_idScanReticleMessage") && document.getElementById("DOM_FT_idScanReticleMessage").innerHTML == "Show Front of ID") {
          if(this.typeOfID == 'passport') {
            document.getElementById("DOM_FT_idScanReticleMessage").innerHTML = 'Show Photo Page of Passport';
          } else if (this.typeOfID == 'drivinglicence') {
            document.getElementById("DOM_FT_idScanReticleMessage").innerHTML = 'Show Photo Side of Driving Licence';
          }
          // clearInterval(this.frontOfIDHack);
        }
      }, 100);

      //<p id="DOM_FT_idScanSelectionMessage" style="margin: 15px auto; color: rgb(89, 89, 89); font-family: &quot;Source Sans Pro&quot;, Helvetica, sans-serif; font-size: 24px;">Prepare to Scan<br>Your ID Document</p>

      if (this.isAQrProcess) {
        this.updateMobileStatus(3);
        this.checksDone.id1 = true;
        this.checksDone.id2 = true;
      } else {
        clearInterval(this.startPoll);
      }
    },
    logToMonitor(msg) {
      axios.post('/api/log', {
        csrf: valToken.csrfToken,
        msg: msg
      })
    },
    writeLog(msg) {
      // show console if in development mode
      if (window.location.hostname == '127.0.0.1') {
        console.log(msg);
      }
    },

    completeProcess() {

     
    // console.log('Marking process as complete');
      // mark the process as completed in the data
      this.checksDone.completion = true;

      window.finished = true;
      this.complete = true;
      this.completed = true;

      if(!this.isRunningOnOtherDevice) {
        this.postJson('Process completed');
      }

      if (this.isAQrProcess) {
        clearInterval(this.startPoll);
      }

      // this.writeLog('checks done...');
      // this.writeLog(this.checksDone);
      // this.writeLog(this.numberOfSteps);
      
      

      if (this.isAQrProcess) {
        this.updateMobileStatus(5);
      }

      // if(!this.handoff) {
      //   this.postJson('Process completed');
      // }

      this.logToMonitor('Process completed successfully');

      

      // remove warning about quitting before saving from DOM
      // document.getElementById("dontQuit").remove();
      // remove animation from progress bar
      document.querySelector(".progress-bar").classList.remove("progress-bar-animated");

      this.updateProgress();

      // this.clearFormData();

    },
    getQrLink() {
      return window.location.protocol + '//' + window.location.hostname + '/qr/' + this.qrCode;
    },
    loadFaceTec() {

      this.logToMonitor('Beginning load of FaceTec assets');

      this.FaceTecLoadingInterval = setInterval(() => {
        this.FaceTecLoading += 1;
      }, 1000);

      this.writeLog('** Loading FaceTec **');

      let facetecScript = document.createElement('script')
      facetecScript.setAttribute('src', '/js/core-sdk/FaceTecSDK.js/FaceTecSDK.js?t='+Date.now())
      document.head.appendChild(facetecScript)

      let FaceTecUIFunctionsScript = document.createElement('script')
      FaceTecUIFunctionsScript.setAttribute('src', '/js/utilities/FaceTecUIFunctions.js?t='+Date.now())

      let FaceTecUtilitiesScript = document.createElement('script')
      FaceTecUtilitiesScript.setAttribute('src', '/js/utilities/FaceTecUtilities.js?t='+Date.now())

      let DeveloperStatusMessagesScript = document.createElement('script')
      DeveloperStatusMessagesScript.setAttribute('src', '/js/utilities/DeveloperStatusMessages.js?t='+Date.now())

      let ThemeHelpersScript = document.createElement('script')
      ThemeHelpersScript.setAttribute('src', '/js/utilities/ThemeHelpers.js?t='+Date.now())

      let AdditionalScreensScript = document.createElement('script')
      AdditionalScreensScript.setAttribute('src', '/js/utilities/AdditionalScreens.js?t='+Date.now())

      let SoundFileUtilitiesScript = document.createElement('script')
      SoundFileUtilitiesScript.setAttribute('src', '/js/utilities/SoundFileUtilities.js?t='+Date.now())

      let PhotoIDMatchProcessorScript = document.createElement('script')
      PhotoIDMatchProcessorScript.setAttribute('src', '/js/processors/PhotoIDMatchProcessor.js?t='+Date.now())

      let EnrollmentProcessorScript = document.createElement('script')
      EnrollmentProcessorScript.setAttribute('src', '/js/processors/EnrollmentProcessor.js?t='+Date.now())

      let facetecControllerScript = document.createElement('script')
      facetecControllerScript.setAttribute('src', '/js/facetec-controller.js?t='+Date.now())

      // Init SDK
      this.FaceTecSDKInterval = setInterval(() => {
        if (typeof FaceTecSDK !== 'undefined' && typeof FaceTecSDK.getStatus() !== 'undefined') {
          // FaceTec initialised
          this.FaceTecSDKLoaded = true;
          this.FaceTecLoadProgress += 10;
          // load up the UI functions
          document.head.appendChild(PhotoIDMatchProcessorScript)
          document.head.appendChild(EnrollmentProcessorScript)
          this.writeLog('FaceTec SDK loaded and responding.');
          clearInterval(this.FaceTecSDKInterval);
        }
      }, 125);

      // Init processors
      this.FaceTecProcessorsInterval = setInterval(() => {
        if (this.FaceTecSDKLoaded) {
          if (typeof PhotoIDMatchProcessor !== 'undefined' && typeof EnrollmentProcessor !== 'undefined') {
            this.FaceTecProcessorsLoaded = true;
            this.FaceTecLoadProgress += 10;
            document.head.appendChild(FaceTecUIFunctionsScript)
            this.writeLog('FaceTec Processors loaded and responding.');
            clearInterval(this.FaceTecProcessorsInterval);
          }
        }
      }, 125);

      // Init UI Functions
      this.FaceTecUIFunctionsInterval = setInterval(() => {
        if (this.FaceTecProcessorsLoaded) {
          if (typeof FaceTecUIFunctions !== 'undefined') {
            this.FaceTecUIFunctionsLoaded = true;
            this.FaceTecLoadProgress += 10;
            //  Load Utilities
            document.head.appendChild(FaceTecUtilitiesScript)

            this.writeLog('FaceTec UI Functions loaded and responding.');
            clearInterval(this.FaceTecUIFunctionsInterval);
          }
        }
      }, 125);

      // Init Utilities
      this.FaceTecUtilitiesInterval = setInterval(() => {
        if (this.FaceTecUIFunctionsLoaded) {
          if (typeof FaceTecUtilities !== 'undefined') {
            this.FaceTecUtilitiesLoaded = true;
            this.FaceTecLoadProgress += 10;
            //  Load Developer Status Messages
            document.head.appendChild(ThemeHelpersScript)
            this.writeLog('FaceTec Utilities loaded and responding.');
            clearInterval(this.FaceTecUtilitiesInterval);
          }
        }
      }, 125);

      // Init Theme Helpers
      this.FaceTecThemeHelpersInterval = setInterval(() => {
        if (this.FaceTecUtilitiesLoaded) {
          if (typeof ThemeHelpers !== 'undefined') {
            this.FaceTecThemeHelopersLoaded = true;
            this.FaceTecLoadProgress += 10;
            document.head.appendChild(AdditionalScreensScript)
            this.writeLog('FaceTec Theme Helpers loaded and responding.');
            clearInterval(this.FaceTecThemeHelpersInterval);
          }
        }
      }, 125);

      // Init Additional Scripts
      this.FaceTecAdditionalScreensInterval = setInterval(() => {
        if (this.FaceTecThemeHelopersLoaded) {
          if (typeof AdditionalScreens !== 'undefined') {
            this.FaceTecAdditionalScreensLoaded = true;
            this.FaceTecLoadProgress += 10;
            document.head.appendChild(SoundFileUtilitiesScript)
            this.writeLog('FaceTec Additional Screens loaded and responding.');
            clearInterval(this.FaceTecAdditionalScreensInterval);
          }
        }
      }, 125);

      // Init Sound Utilities
      this.FaceTecSoundUtilitiesInterval = setInterval(() => {
        if (this.FaceTecAdditionalScreensLoaded) {
          if (typeof SoundFileUtilities !== 'undefined') {
            this.FaceTecSoundUtilitiesLoaded = true;
            this.FaceTecLoadProgress += 10;
            // load processors
            document.head.appendChild(DeveloperStatusMessagesScript)
            this.writeLog('FaceTec Sound Utilities loaded and responding.');
            clearInterval(this.FaceTecSoundUtilitiesInterval);
          }
        }
      }, 125);

      // Init Developer Status Messages
      this.FaceTecStatusMessagesInterval = setInterval(() => {
        if (this.FaceTecSoundUtilitiesLoaded) {
          if (typeof DeveloperStatusMessages !== 'undefined') {
            this.FaceTecStatusMessagesLoaded = true;
            this.FaceTecLoadProgress += 10;
            document.head.appendChild(facetecControllerScript)
            this.writeLog('FaceTec Developer Status Messages loaded and responding.');
            clearInterval(this.FaceTecStatusMessagesInterval);
          }
        }
      }, 125);

      // Init processors
      this.FaceTecControllerInterval = setInterval(() => {
        if (this.FaceTecStatusMessagesLoaded) {
          // this.writeLog(FaceTecSDK);

          if (typeof FaceTec !== 'undefined') {

            // console.log("FaceTec Status is: " + FaceTecSDK.getStatus());

            switch (FaceTecSDK.getStatus()) {


              // Network Issues
              case FaceTecSDK.FaceTecSDKStatus.NetworkIssues:
                this.logToMonitor('FaceTec browser elements experienced network issues');
                this.postJson('FaceTec browser elements experienced network issues');
                this.writeLog('Network issues!');
                clearInterval(this.FaceTecControllerInterval);
                this.loadFaceTec();
                break;

              // Never initialized
              case FaceTecSDK.FaceTecSDKStatus.NeverInitialized:
                // try to load again
                this.logToMonitor('Problem loading FaceTec SDK - attempting reload');
                this.postJson('Problem loading FaceTec SDK - attempting reload');
                clearInterval(this.FaceTecControllerInterval);
                this.loadFaceTec();
                break;

              // Device Not Supported
              case FaceTecSDK.FaceTecSDKStatus.DeviceNotSupported:
                this.logToMonitor('Device/browser unsupported by FaceTec - showing error');
                this.postJson('Device/browser unsupported by FaceTec - showing error');
                this.logBadUserAgent(navigator.userAgent);
                clearInterval(this.FaceTecControllerInterval);
                this.throwGeneralError(this.$t('error.unsupported_device_or_browser'), this.$t("error.unsupported"));
                break;

              // Device In Landscape Mode
              case FaceTecSDK.FaceTecSDKStatus.DeviceInLandscapeMode:
                this.logToMonitor('Device in landscape mode, asked user to change to portrait');
                this.postJson('Device in landscape mode, asked user to change to portrait');
                clearInterval(this.FaceTecControllerInterval);
                this.throwFaceTecError("Please take your device out of landscape mode by spinning it around 90 degrees to portrait. Then press 'Next' to try again.");
                break;

              // Device Not Supported
              case FaceTecSDK.FaceTecSDKStatus.GetUserMediaRemoteHTTPNotSupported:
                this.logToMonitor('Device/browser unsupported by FaceTec - showing error');
                this.postJson('Device/browser unsupported by FaceTec - showing error');
                this.logBadUserAgent(navigator.userAgent);
                clearInterval(this.FaceTecControllerInterval);
                this.throwGeneralError(this.$t('error.unsupported_device_or_browser'), this.$t("error.unsupported"));
                break;

              // FaceTec SDK ready to go
              case FaceTecSDK.FaceTecSDKStatus.Initialized:
                this.logToMonitor('FaceTec loaded successfully');
                this.postJson('FaceTec loaded successfully');
                this.faceTecDisabled = false;
                this.FaceTecLoadProgress += 10;
                // document.head.appendChild(facetecControllerScript)
                this.writeLog('FaceTec Initialised.');
                clearInterval(this.FaceTecControllerInterval);
                clearInterval(this.FaceTecLoadingInterval);

                // if this needs a QR code....
                if (!this.isAQrProcess) {
                  axios
                    .post('/api/getqrcode', {
                      csrf: valToken.csrfToken
                    })
                    .then((response) => {
                      this.qrCode = response.data;
                      if (this.qrCode !== null) {
                        new QRCode(document.getElementById("qrcode"), this.getQrLink());
                        this.checkMobileStatus();
                      }
                    })
                } else {
                  this.updateMobileStatus(1);
                }
                break;
            }
          }
        }
      }, 125);
    },
    checkMobileStatus() {
      if (!this.isMobile()) {
        // watch for mobile opening
        this.startPoll = setInterval(() => {
          axios
            .post('/api/poll', {
              csrf: valToken.csrfToken,
              code: this.qrCode
            })
            .then((response) => {
              this.mobileStatus = response.data;
              if (response.data == 1) {
                this.isRunningOnOtherDevice = true;
                this.mobileIsReady = true;
              }
              if (response.data == 2) {
                this.isRunningOnOtherDevice = true;
                this.mobileIsReady = true;
                this.checksDone.liveness = true;
              }
              if (response.data == 3) {
                this.isRunningOnOtherDevice = true;
                this.mobileIsReady = true;
                this.mobileIDdone = true;
                this.checksDone.id1 = true;
                this.checksDone.id2 = true;
              }
              if (response.data >= 4) {
                this.mobileProcessDone = true;
                this.checksDone.facetec = true;
                this.isRunningOnOtherDevice = true;
                // this.complete = true;
                // this.checksDone.completion = true;
                this.nextStep();
              }
              this.updateProgress();
            })
        }, 2000);
      }
    },
    updateMobileStatus(status) {
      axios.post('/api/updatemobilestatus', {
        csrf: valToken.csrfToken,
        code: this.code,
        status: status
      });
    },
    throwFaceTecError(msg = null) {
      this.faceTecDisabled = false;
      this.faceTecError = true;
      
      if (msg !== null) {
        this.faceTecErrorMsg = msg;
        this.postJson('FaceTec error - ' + msg);
      } else {
        this.faceTecErrorMsg = this.$t('error.facetec_general');
        // this.postJson('Generic FaceTec error');
      }
    },
    logBadUserAgent(ua) {
      axios
        .post('/api/baduseragent', {
          csrf: valToken.csrfToken,
          useragent: ua
        })
        .then(() => {
          this.writeLog('Logged bad user agent: ' + ua);
        })
    },
    throwGeneralError(title = this.$t('label.error'), msg = this.$t('error.generic_error')) {
      this.showProcess = false;
      this.error_type = 'full';
      this.error = true;
      this.error_title = title;
      this.error_msg = msg;
      this.error_btn_link = "/verify";
      this.error_btn_text = this.$t("button.start_again")
    },
    postJson(reason = null) {
      // let's build this up

      var json = {
        first_name: (this.step2_data.first_name ? this.step2_data.first_name : null),
        middle_names: (this.step2_data.middle_names ? this.step2_data.middle_names : null),
        surname: (this.step2_data.surname ? this.step2_data.surname : null),
        national_insurance_number: (this.step2_data.national_insurance_number !== null ? this.step2_data.national_insurance_number.toUpperCase() : null),
        email: (this.step2_data.email !== null ? this.step2_data.email.toLowerCase() : null),
        dob_date: (this.step2_data.dob_date ? this.step2_data.dob_date : null),
        dob_month: (this.step2_data.dob_month ? this.step2_data.dob_month : null),
        dob_year: (this.step2_data.dob_year ? this.step2_data.dob_year : null),
        time_submitted: (this.step2_data.time_submitted ? this.step2_data.time_submitted : null),
        number: (this.step2_data.number ? this.step2_data.number : null),
        token: (this.step2_data.token ? this.step2_data.token : null),
        amlCheck: (this.step2_data.amlCheck ? this.step2_data.amlCheck : null),
        downlinkSpeed: (this.step2_data.downlinkSpeed ? this.step2_data.downlinkSpeed : null),
        address_line_1: (this.step2_data.address_line_1 ? this.step2_data.address_line_1 : null),
        address_line_2: (this.step2_data.address_line_2 ? this.step2_data.address_line_2 : null),
        address_line_3: (this.step2_data.address_line_3 ? this.step2_data.address_line_3 : null),
        address_line_4: (this.step2_data.address_line_4 ? this.step2_data.address_line_4 : null),
        address_line_5: (this.step2_data.address_line_5 ? this.step2_data.address_line_5 : null),
        address_line_6: (this.step2_data.address_line_6 ? this.step2_data.address_line_6 : null),
        postcode: (this.step2_data.postcode !== null ? this.step2_data.postcode.toUpperCase() : null),
        phone: (this.step2_data.phone ? this.step2_data.phone : null),
        mobile: (this.step2_data.mobile ? this.step2_data.mobile : null),
        dialing_code: (this.step2_data.mobileDiallingCode ? this.step2_data.mobileDiallingCode : null),
        country: (this.step2.countries && this.step2_data.country ? this.step2.countries[this.step2_data.country] : null),
        user_agent: (this.step2_data.user_agent ? this.step2_data.user_agent : null),
        qr_code: (this.code ? this.code : null),
        step: (this.thisStep ? this.thisStep : null),
        csrf: valToken.csrfToken,
        completed: this.checksDone.completion,
        reason: (reason ? reason : null),
        faceTec: {
          ID1: (this.IDandLivenessScan ? this.IDandLivenessScan : null),
          ID2: (this.SecondID ? this.SecondID : null),
          DOC1: (this.Document1 ? this.Document1 : null),
          DOC2: (this.Document2 ? this.Document2 : null),
          liveness: (this.Liveness ? this.Liveness : null),
        },
        handoff: this.handoff,
        mobileverify: (this.step2_data.mobilePINconfirmed ? this.step2_data.mobilePINconfirmed : null),
        emailverify: (this.step2_data.emailPINconfirmed ? this.step2_data.emailPINconfirmed : null),
        questions: (this.questions ? this.questions : null),
        time_taken: (900 - this.timeToComplete),
        bioconsent: this.step2_data.bioconsent,
        bankconfirmed: false
      };

      // tidy up JSON a bit
      if('lowQualityAuditTrail' in json.faceTec.liveness) {
        delete(json.faceTec.liveness.lowQualityAuditTrail);
      }
      if('faceScan' in json.faceTec.liveness) {
        delete(json.faceTec.liveness.faceScan);
      }


      this.writeLog('postJson() running. Output:');
      this.writeLog(json);
      // now post it
      axios
        .post('/api/postdata', json)
        .then((response) => {
          this.writeLog(response.data);
        })
    },
    preventNav(event) {
      if (!this.showProcess) return;
      event.preventDefault();
      // Chrome requires returnValue to be set.
      event.returnValue = "";
    }
  },
  beforeMount() {
    window.addEventListener("beforeunload", this.preventNav);
  },

  mounted() {

    // Check for unsupported environments
    const userAgent = navigator.userAgent || navigator.vendor || window.opera;
    const isAndroid = userAgent.indexOf('Android') > -1;
    const isFirefox = userAgent.indexOf('Firefox') > -1;
    const isFirefoxiOS = userAgent.indexOf('FxiOS') > -1;
    const isInFB = userAgent.indexOf('FB_IAB') > -1;
    const isInIG = userAgent.indexOf('Instagram') > -1;

    if (isAndroid && isFirefox) {
      this.unsupportedFirefox = true;
    }
    if (!isAndroid && isFirefoxiOS) {
      this.unsupportedFirefoxiOS = true;
    }
    if (isInFB || isInIG) {
      this.inAppBrowser = true;
    }

    

    setTimeout(() => {
      // set up default language messages
      this.faceTecErrorMsg = this.$t('error.facetec_general');
      window.facetec_liveness_confirmed = this.$t('facetec.messages.liveness_confirmed');
      window.facetec_id_scan_complete = this.$t('facetec.messages.id_scan_complete');
      window.facetec_front_id_scan_completed = this.$t('facetec.messages.front_id_scan_completed');
      window.facetec_front_id_scanned = this.$t('facetec.messages.front_id_scanned');
      window.facetec_passport_scan_completed = this.$t('facetec.messages.passport_scan_completed');
      window.facetec_photo_id_scan_completed = this.$t('facetec.messages.photo_id_scan_completed');
      window.facetec_face_didnt_match_highly_enough = this.$t('facetec.messages.face_didnt_match_highly_enough');
      window.facetec_id_not_fully_visible = this.$t('facetec.messages.id_not_fully_visible');
      window.facetec_id_text_not_legible = this.$t('facetec.messages.id_text_not_legible');
      window.facetec_id_type_mismatch_try_again = this.$t('facetec.messages.id_type_mismatch_try_again');
      window.facetec_uploading_encrypted_id_scan = this.$t('facetec.messages.uploading_encrypted_id_scan');
      window.facetec_uploading_encrypted_face_scan = this.$t('facetec.messages.uploading_encrypted_face_scan');
      window.facetec_still_uploading_slow_connection = this.$t('facetec.messages.still_uploading_slow_connection');
      window.facetec_upload_complete = this.$t('facetec.messages.upload_complete');
      window.facetec_processing_id_scan = this.$t('facetec.messages.processing_id_scan');
      window.facetec_uploading_encrypted_back_of_id = this.$t('facetec.messages.uploading_encrypted_back_of_id');
      window.facetec_processing_back_of_id = this.$t('facetec.messages.processing_back_of_id');
      window.facetec_uploading_your_confirmed_info = this.$t('facetec.messages.uploading_your_confirmed_info');
      window.facetec_processing = this.$t('facetec.messages.processing');
      window.facetec_frame_your_face_in_oval = this.$t('facetec.messages.frame_your_face_in_oval');
      window.facetec_frame_your_face_in_oval_and_hold_still = this.$t('facetec.messages.frame_your_face_in_oval_and_hold_still');
      window.error_id_scan_didnt_complete = this.$t('error.id_scan_didnt_complete');
    }, 5000);


    this.getMonths();
    

    window.EventBus.$on('photoIDscanError', () => {
      if (!this.isAQrProcess) {
        this.checkMobileStatus();
      }
      this.IDScanLoading = false;
      this.throwFaceTecError();
      // this.postJson('Error scanning ID document');
    });

    window.EventBus.$on('postJson', msg => {
      this.postJson(msg);
    });

    window.EventBus.$on('livenessScanError', () => {
      if (!this.isAQrProcess) {
        this.checkMobileStatus();
      }
      this.throwFaceTecError();

      if (this.isAQrProcess) {
        // reset mobile status back to beginning
        this.updateMobileStatus(1);
      }

      // this.postJson('Error performing liveness scan');

    });

    window.EventBus.$on('photoIDscan', data => {

      // this.checksDone.facetec = true;

      if (!this.isAQrProcess) {
        this.checkMobileStatus();
      }
      this.writeLog(data);

      this.IDandLivenessScan.success = data.responseJSON.success;
      this.IDandLivenessScan.moreData = data.responseJSON;
      this.IDandLivenessScan.sessionId = data.scanResult.sessionId;
      this.IDandLivenessScan.isCompletelyDone = data.scanResult.isCompletelyDone;
      // this.IDandLivenessScan.backImages = [];
      // this.IDandLivenessScan.frontImages = [];
      this.IDandLivenessScan.backImages = data.scanResult.backImages[0]; // only one image
      this.IDandLivenessScan.frontImages = data.scanResult.frontImages[0]; // only one image


      if (this.isAQrProcess) {
        this.updateMobileStatus(4);
      } else {
        clearInterval(this.startPoll);
      }

      this.checksDone[this.currentIDScan] = true;

      this.nextStep();

    });

    window.EventBus.$on('livenessDone', data => {

      if (!this.isAQrProcess) {
        this.checkMobileStatus();
      }

      this.logToMonitor('Liveness confirmed');
      this.Liveness = data.scanResult;
      this.externalDatabaseRefID = data.externalDatabaseRefID;
      window.externalDatabaseRefID = data.externalDatabaseRefID;
      this.writeLog(data);

      // this.writeLog('external ref:');
      // this.writeLog(this.externalDatabaseRefID);

      this.postJson('Liveness confirmed');

      this.checksDone.liveness = true;

      if (this.isAQrProcess) {
        this.updateMobileStatus(3);
      } else {
        // clearInterval(this.startPoll);
      }

      this.nextStep();

    });

    window.EventBus.$on('photoIDscanFail', () => {
      this.postJson('Photo ID scan failure');
      this.logToMonitor('Photo ID scan failed');
      if (!this.isAQrProcess) {
        this.checkMobileStatus();
      } else {
        this.updateMobileStatus(3);
      }
      
    });


    if (this.code && this.code !== null) {

      this.handoff = true;

      this.isAQrProcess = true;

      // We have a QR code so we're going straight to FaceTec
      this.loadFaceTec();
      // Now load other FaceTec scripts
      this.timeToComplete = 600;
      this.referenceCode = '00000000';
      // this.thisStep = 4;

      this.thisStep = 'facetec';
    
      // this.step1_complete = true;
      // this.step2_part_a_complete = true;
      // this.step2_part_b_complete = true;
      // this.step2_part_c_complete = true;
      // this.step2_part_d_complete = true;
      document.querySelector('#loader').style.display = 'none';
      this.showProcess = true;

    } else {

      this.showProcess = true;
      document.querySelector('#loader').style.display = 'none';
      this.referenceCode = this.rn;

      

    }

    // this.skipToFaceTec();

  

  },
  // watch: {
  //   thisStep: {
  //     handler(step) {

  //       // if (step == 3) {
  //       //   setTimeout(() => {
  //       //     this.$refs.addressLine1.focus();
  //       //   }, 250);
  //       // }
  //       // if (step == 4) { // FaceTec step
  //       //   // dump current data
  //       //   this.writeLog('The data so far:');
  //       //   this.writeLog(this.step2_data);
          
  //       //   // this.loadFaceTec();
  //       //   if (this.code == null) {
  //       //     this.loadFaceTec();
  //       //   }
  //       // }
  //       // if (step == 'extraquestions') {
  //       //  // maybe do something?
  //       // }
  //     },
  //     deep: true
  //   }

  // }
}
</script>
